import { WIDGET_METRIC_GROUPS } from "@services/math/math.const";
import styles from "./styles.module.scss";
import { useEffect, useState, useRef, useCallback } from "react";
import Arrow from "@assets/icons/Arrow";
import { useCanvaStore } from "src/store/canva/canva.state";
import { MetricCard } from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder/MetricCard";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { WidgetClass } from "@services/widget.service";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import Setting from "@assets/icons/setting";
import classNames from "classnames";
import { Spin } from "antd";
import "../modal.scss";
import { SettingsModal } from "../SettingsSmallModal";
import { WidgetMetricKey } from "@services/math/math.service";
import WidgetIcon from "@assets/icons/widgetsDNDBtn";

interface AdvancedViewProps {
  isCRO?: boolean;
  showHistorical?: boolean;
}

export const AdvancedView: React.FC<AdvancedViewProps> = ({
  isCRO,
  showHistorical,
}) => {
  const [openedAccordion, setOpenedAccordion] = useState<string[]>(
    WIDGET_METRIC_GROUPS.map((group) => group.title),
  );
  const [openGroupModal, setOpenGroupModal] = useState<string | null>(null);
  const { adSpend: saturation } = useAdsAnaliticsStore().adSlides;
  const { isWhatIf, campaignsTab, saturationKeys } = useCanvaStore();
  const { dateRange, compareType, availableWidgets } = useDashboardStore();
  const { compareWith } = useAdsAnaliticsStore();
  const { startDate, endDate } = dateRange;
  const [widgets, setWidgets] = useState<IWidget[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWidgets, setSelectedWidgets] =
    useState<WidgetMetricKey[]>(availableWidgets);
  const [choosedWidgets, setChoosedWidgets] =
    useState<WidgetMetricKey[]>(selectedWidgets);
  const [historicalShowGroup, setHistoricalShowGroup] = useState<string[]>([]);

  const [draggedGroup, setDraggedGroup] = useState<string | null>(null);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [targetIndex, setTargetIndex] = useState<number | null>(null);

  const [widgetOrder, setWidgetOrder] = useState<
    Record<string, WidgetMetricKey[]>
  >({});

  const itemRefsMap = useRef<Record<string, (HTMLDivElement | null)[]>>({});
  const ghostRef = useRef<HTMLDivElement | null>(null);
  const emptyImageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    emptyImageRef.current = img;
  }, []);

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgets,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
        compareType,
        historicalShowGroup?.length > 0,
        isWhatIf,
        saturation,
        campaignsTab,
        saturationKeys,
      );
      setWidgets(response);

      const newOrder: Record<string, WidgetMetricKey[]> = {};
      WIDGET_METRIC_GROUPS.forEach((group) => {
        if (!widgetOrder[group.title]) {
          newOrder[group.title] = [...group.keys];
        }
      });

      if (Object.keys(newOrder).length > 0) {
        setWidgetOrder((prev) => ({ ...prev, ...newOrder }));
      }

      setIsLoading(false);
    };
    fetchWidgets();
  }, [
    selectedWidgets,
    dateRange,
    compareWith,
    compareType,
    showHistorical,
    historicalShowGroup,
    saturation,
    isWhatIf,
  ]);

  useEffect(() => {
    WIDGET_METRIC_GROUPS.forEach((group) => {
      if (!itemRefsMap.current[group.title]) {
        itemRefsMap.current[group.title] = [];
      }

      const groupWidgets = widgetOrder[group.title] || group.keys;
      itemRefsMap.current[group.title] = itemRefsMap.current[group.title].slice(
        0,
        groupWidgets.length,
      );
    });
  }, [widgetOrder]);

  const toggleAccordion = (key: string) => {
    if (openedAccordion === null) setOpenedAccordion([key]);
    else if (openedAccordion?.includes(key))
      setOpenedAccordion(openedAccordion.filter((k) => k !== key));
    else setOpenedAccordion([...openedAccordion, key]);
  };

  const resetToDefault = (groupTitle: string) => {
    const defaultMetrics = WIDGET_METRIC_GROUPS.find(
      (group) => group.title === groupTitle,
    )?.keys;

    setChoosedWidgets((prev) => {
      const newWidgets = prev.filter(
        (widget) => !defaultMetrics?.includes(widget),
      );
      return [...newWidgets, ...defaultMetrics];
    });

    if (defaultMetrics) {
      setWidgetOrder((prev) => ({
        ...prev,
        [groupTitle]: [...defaultMetrics],
      }));
    }
  };

  const onApply = () => {
    setSelectedWidgets(choosedWidgets);
    setOpenGroupModal(null);
  };

  const handleDragStart = useCallback(
    (e: React.DragEvent, groupTitle: string, index: number) => {
      e.dataTransfer.effectAllowed = "move";

      if (emptyImageRef.current) {
        e.dataTransfer.setDragImage(emptyImageRef.current, 0, 0);
      }

      const ghostElement = document.createElement("div");
      ghostElement.className = styles.ghostElement;
      document.body.appendChild(ghostElement);
      ghostRef.current = ghostElement;

      setDraggedGroup(groupTitle);
      setDraggedIndex(index);

      if (!itemRefsMap.current[groupTitle]) {
        itemRefsMap.current[groupTitle] = [];
      }

      const draggedItem = itemRefsMap.current[groupTitle][index];
      if (draggedItem) {
        draggedItem.classList.add(styles.isDragging);

        const rect = draggedItem.getBoundingClientRect();

        ghostElement.style.width = `${rect.width}px`;
        ghostElement.style.height = `${rect.height}px`;
        ghostElement.style.position = "fixed";
        ghostElement.style.top = "0";
        ghostElement.style.left = "0";
        ghostElement.style.pointerEvents = "none";
        ghostElement.style.zIndex = "9999";
        ghostElement.style.transform = `translate(${e.clientX - 100}px, ${e.clientY - 50}px)`;
        ghostElement.innerHTML = draggedItem.innerHTML;

        const ghostHandles = ghostElement.querySelectorAll(
          `.${styles.dragHandle}`,
        );
        ghostHandles.forEach((handle) => handle.remove());
      }
    },
    [],
  );

  const handleDrag = useCallback((e: React.DragEvent) => {
    if (ghostRef.current && e.clientX > 0 && e.clientY > 0) {
      ghostRef.current.style.transform = `translate(${e.clientX - 100}px, ${e.clientY - 50}px)`;
    }
  }, []);

  const handleDragOver = useCallback(
    (e: React.DragEvent, groupTitle: string, index: number) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";

      if (groupTitle === draggedGroup && index !== draggedIndex) {
        setTargetIndex(index);
      }
    },
    [draggedGroup, draggedIndex],
  );

  const handleDragEnter = useCallback(
    (e: React.DragEvent, groupTitle: string, index: number) => {
      e.preventDefault();
      if (groupTitle === draggedGroup && index !== draggedIndex) {
        setTargetIndex(index);
      }
    },
    [draggedGroup, draggedIndex],
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent, index: number) => {
      if (e.currentTarget.contains(e.relatedTarget as Node)) {
        return;
      }

      if (index === targetIndex) {
        setTargetIndex(null);
      }
    },
    [targetIndex],
  );

  const handleDragEnd = useCallback(
    (e: React.DragEvent) => {
      if (ghostRef.current) {
        document.body.removeChild(ghostRef.current);
        ghostRef.current = null;
      }

      if (
        draggedGroup &&
        draggedIndex !== null &&
        targetIndex !== null &&
        draggedIndex !== targetIndex
      ) {
        const groupWidgets = [...(widgetOrder[draggedGroup] || [])];
        const [draggedWidget] = groupWidgets.splice(draggedIndex, 1);
        groupWidgets.splice(targetIndex, 0, draggedWidget);

        setWidgetOrder((prev) => ({
          ...prev,
          [draggedGroup]: groupWidgets,
        }));
      }

      if (draggedGroup && draggedIndex !== null) {
        const draggedItem = itemRefsMap.current[draggedGroup][draggedIndex];
        if (draggedItem) {
          draggedItem.classList.remove(styles.isDragging);
        }
      }

      setDraggedGroup(null);
      setDraggedIndex(null);
      setTargetIndex(null);
    },
    [draggedGroup, draggedIndex, targetIndex, widgetOrder],
  );

  if (isLoading) return <Spin />;

  return (
    <div className={classNames(styles.container, { [styles.cro]: isCRO })}>
      {WIDGET_METRIC_GROUPS.map((group) => {
        const groupWidgetKeys = widgetOrder[group.title] || group.keys;

        return (
          <div
            key={group.title}
            className={classNames(
              styles.accordion,
              openedAccordion?.includes(group.title) && styles.open,
            )}
          >
            <button
              className={styles.accordion__title}
              onClick={() => toggleAccordion(group.title)}
            >
              <div className={styles.name}>
                <Arrow />
                {group.title}
              </div>
              <button
                className={styles.setting}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenGroupModal(group.title);
                }}
              >
                <Setting />
              </button>
            </button>
            <div
              className={classNames(styles.accordion__content, {
                [styles.cro]: isCRO,
              })}
            >
              {groupWidgetKeys.map((metric, index) => {
                const widget = widgets?.find((w) => w.id === metric);
                if (!widget) return null;

                return isWhatIf ? (
                  <MetricCard key={metric} metric={widget} />
                ) : (
                  <div
                    key={metric}
                    ref={(el) => {
                      if (!itemRefsMap.current[group.title]) {
                        itemRefsMap.current[group.title] = [];
                      }
                      itemRefsMap.current[group.title][index] = el;
                    }}
                    className={classNames(styles.widgetWrapper, {
                      [styles.isTarget]:
                        draggedGroup === group.title && targetIndex === index,
                    })}
                    draggable
                    onDragStart={(e) => handleDragStart(e, group.title, index)}
                    onDrag={handleDrag}
                    onDragOver={(e) => handleDragOver(e, group.title, index)}
                    onDragEnter={(e) => handleDragEnter(e, group.title, index)}
                    onDragLeave={(e) => handleDragLeave(e, index)}
                    onDragEnd={handleDragEnd}
                  >
                    <div className={styles.dragHandle}>
                      {typeof WidgetIcon === "function" ? <WidgetIcon /> : "≡"}
                    </div>
                    <Widget
                      widget={widget}
                      additionalClass={classNames(styles.widget, {
                        [styles.historical]: historicalShowGroup?.includes(
                          group.title,
                        ),
                      })}
                    />
                  </div>
                );
              })}
            </div>
            <SettingsModal
              open={openGroupModal === group.title}
              title={group.title}
              availableKeys={group.keys}
              selectedKeys={choosedWidgets}
              onClose={() => setOpenGroupModal(null)}
              onChange={(keys) => setChoosedWidgets(keys)}
              resetToDefault={() => resetToDefault(group.title)}
              onApply={onApply}
              setShowHistorical={(show) => {
                show
                  ? setHistoricalShowGroup([
                      ...historicalShowGroup,
                      group.title,
                    ])
                  : setHistoricalShowGroup(
                      historicalShowGroup?.filter((g) => g !== group.title),
                    );
              }}
              showHistorical={historicalShowGroup?.includes(group.title)}
            />
          </div>
        );
      })}
    </div>
  );
};
