import React, { useState } from "react";
import { Table } from "antd";
import "./table.scss";
import { getColumns, tableData } from "./mock";
import { useIsMobile } from "src/utils/isMobile";

export const PnlExpandableTable: React.FC = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const isMobile = useIsMobile();
  const columns = getColumns(isMobile);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys((prev) => [...prev, record.key]);
    } else {
      setExpandedRowKeys((prev) => prev.filter((k) => k !== record.key));
    }
  };

  return (
    <div className="pnl-expandable-table">
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="key"
        pagination={false}
        expandable={{
          expandedRowKeys,
          onExpand: handleExpand,
          rowExpandable: (record) =>
            Array.isArray(record.children) && record.children.length > 0,
          indentSize: 20,
        }}
      />
    </div>
  );
};
