import { v4 as uuid } from "uuid";
import { getWidgetSize } from "@pages/CanvasEditor/Elements/utils";
import { ELEMENT_ID } from "./element-id";
import { CanvaElement, Preset } from "./canva.types";
import salesPreview from "@assets/img/presets/sales.png";
import goalPreview from "@assets/img/presets/goal.png";
import launchPreview from "@assets/img/presets/launch.png";
import relaunchPreview from "@assets/img/presets/prelaunch.png";

export const defaultElements: CanvaElement[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.SUMMARY_AUDIT,
    x: 0,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.SUMMARY_AUDIT),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
    x: 0,
    y: 50,
    size: getWidgetSize(ELEMENT_ID.EXECUTIVE_SUMMARY),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SALES,
    x: 8,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.ADS_SALES),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ORGANIC_SALES,
    x: 8,
    y: 50,
    size: getWidgetSize(ELEMENT_ID.ORGANIC_SALES),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SANKEY,
    x: 16,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.ADS_SANKEY),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.FUNNEL,
    x: 16,
    y: 50,
    size: getWidgetSize(ELEMENT_ID.FUNNEL),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS,
    x: 24,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRODUCT_DETAILS,
    x: 26,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.PRODUCT_DETAILS),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.CONTENT_OPTIMIZATION,
    x: 31,
    y: 14,
    size: getWidgetSize(ELEMENT_ID.CONTENT_OPTIMIZATION),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRICE_OPTIMIZATION,
    x: 31,
    y: 8,
    size: getWidgetSize(ELEMENT_ID.PRICE_OPTIMIZATION),
    active: true,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ASPECT_OPTIMIZATION,
    x: 31,
    y: 16,
    size: getWidgetSize(ELEMENT_ID.ASPECT_OPTIMIZATION),
    active: true,
  },
];

export const presets: Preset[] = [
  {
    name: "Sales Performance",
    previewImage: salesPreview,
    elements: [
      {
        id: uuid(),
        elementId: ELEMENT_ID.SUMMARY_AUDIT,
        x: 0,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.SUMMARY_AUDIT),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.SALES_PERFORMANCE,
        x: 0,
        y: 61,
        size: getWidgetSize(ELEMENT_ID.SALES_PERFORMANCE),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.FUNNEL,
        x: 0,
        y: 116,
        size: getWidgetSize(ELEMENT_ID.FUNNEL),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
        x: 8,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.EXECUTIVE_SUMMARY),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.ADS_SALES,
        x: 8,
        y: 61,
        size: getWidgetSize(ELEMENT_ID.ADS_SALES),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.ADS_SALES_SLIDERS,
        x: 8,
        y: 130,
        size: getWidgetSize(ELEMENT_ID.ADS_SALES_SLIDERS),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.ORGANIC_SALES,
        x: 8,
        y: 181,
        size: getWidgetSize(ELEMENT_ID.ORGANIC_SALES),
        active: true,
      },
    ],
  },
  {
    name: "Goal Setting",
    previewImage: goalPreview,
    elements: [
      {
        id: uuid(),
        elementId: ELEMENT_ID.SUMMARY_AUDIT,
        x: 0,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.SUMMARY_AUDIT),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.SALES_PERFORMANCE,
        x: 0,
        y: 61,
        size: getWidgetSize(ELEMENT_ID.SALES_PERFORMANCE),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.FUNNEL,
        x: 0,
        y: 116,
        size: getWidgetSize(ELEMENT_ID.FUNNEL),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.ORGANIC_SALES,
        x: 0,
        y: 192,
        size: getWidgetSize(ELEMENT_ID.ORGANIC_SALES),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
        x: 8,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.EXECUTIVE_SUMMARY),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.ADS_SALES_SLIDERS,
        x: 8,
        y: 56,
        size: getWidgetSize(ELEMENT_ID.ADS_SALES_SLIDERS),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.GOAL_SETTING,
        x: 13,
        y: 56,
        size: getWidgetSize(ELEMENT_ID.GOAL_SETTING),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.ADS_SALES,
        x: 16,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.ADS_SALES),
        active: true,
      },
    ],
  },
  {
    name: "Product Launch",
    previewImage: launchPreview,
    elements: [
      {
        id: uuid(),
        elementId: ELEMENT_ID.SUMMARY_AUDIT,
        x: 0,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.SUMMARY_AUDIT),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.MARKET_SALES_PERFORMANCE,
        x: 0,
        y: 61,
        size: getWidgetSize(ELEMENT_ID.MARKET_SALES_PERFORMANCE),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.BRAND_SALES_PERFORMANCE,
        x: 0,
        y: 116,
        size: getWidgetSize(ELEMENT_ID.BRAND_SALES_PERFORMANCE),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
        x: 8,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.EXECUTIVE_SUMMARY),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.LAUNCH_PRODUCT,
        x: 8,
        y: 56,
        size: getWidgetSize(ELEMENT_ID.LAUNCH_PRODUCT),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.UNDEFINED,
        x: 16,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.UNDEFINED),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.MARKET_LEVEL_SALES_DISTRIBUTION,
        x: 16,
        y: 56,
        size: getWidgetSize(ELEMENT_ID.MARKET_LEVEL_SALES_DISTRIBUTION),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.MARKET_OVERVIEW,
        x: 16,
        y: 130,
        size: getWidgetSize(ELEMENT_ID.MARKET_OVERVIEW),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.MARKET_SHARE,
        x: 20,
        y: 130,
        size: getWidgetSize(ELEMENT_ID.MARKET_SHARE),
        active: true,
      },
    ],
  },
  {
    name: "Product Relaunch",
    previewImage: relaunchPreview,
    elements: [
      {
        id: uuid(),
        elementId: ELEMENT_ID.SUMMARY_AUDIT,
        x: 0,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.SUMMARY_AUDIT),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.MARKET_SALES_PERFORMANCE,
        x: 0,
        y: 61,
        size: getWidgetSize(ELEMENT_ID.MARKET_SALES_PERFORMANCE),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.BRAND_SALES_PERFORMANCE,
        x: 0,
        y: 116,
        size: getWidgetSize(ELEMENT_ID.BRAND_SALES_PERFORMANCE),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
        x: 8,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.EXECUTIVE_SUMMARY),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.LAUNCH_PRODUCT,
        x: 8,
        y: 56,
        size: getWidgetSize(ELEMENT_ID.LAUNCH_PRODUCT),
        active: true,
      },
      {
        id: uuid(),
        elementId: ELEMENT_ID.UNDEFINED,
        x: 16,
        y: 14,
        size: getWidgetSize(ELEMENT_ID.UNDEFINED),
        active: true,
      },
    ],
  },
];
