import { Checkbox, Tooltip } from "antd";
import { Settings } from "lucide-react";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import classNames from "classnames";
import ModalBox from "@components/ModalBox";
import { getSaturationKeyField } from "../utils";

interface ColumnConfig {
  key: string;
  title: string;
  info?: string;
  isWhatIf?: boolean;
}

interface ColumnsSettingsModalProps {
  availableColumns: ColumnConfig[];
  selectedColumns: string[];
  onChange: (keys: string[]) => void;
  getDefaultColumns: () => string[];
  selectedTab: TypeTab;
  className?: string;
  isWhatIfEnabled?: boolean;
}

export const ColumnsSettingsModal: React.FC<ColumnsSettingsModalProps> = ({
  availableColumns,
  selectedColumns,
  onChange,
  getDefaultColumns,
  selectedTab,
  className,
  isWhatIfEnabled = false,
}) => {
  const [choosedColumns, setChoosedColumns] =
    useState<string[]>(selectedColumns);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedCount, setSelectedCount] = useState(0);

  const primaryColumnKey = getSaturationKeyField(selectedTab);

  useEffect(() => {
    setChoosedColumns(selectedColumns);

    const regularColumnsCount = selectedColumns.filter((colKey) => {
      const column = availableColumns.find((col) => col.key === colKey);
      return column && !column.isWhatIf && colKey !== primaryColumnKey;
    }).length;

    setSelectedCount(regularColumnsCount);
  }, [selectedColumns, availableColumns, primaryColumnKey]);

  const toggleColumn = (key: string) => {
    if (key === primaryColumnKey) {
      return;
    }

    const column = availableColumns.find((col) => col.key === key);
    const isWhatIfColumn = column?.isWhatIf || false;

    if (choosedColumns.includes(key)) {
      if (!isWhatIfColumn && selectedCount <= 5) {
        console.warn("At least 5 columns must be selected");
        return;
      }

      const newColumns = choosedColumns.filter((k) => k !== key);
      setChoosedColumns(newColumns);

      if (!isWhatIfColumn) {
        setSelectedCount((prev) => prev - 1);
      }
    } else {
      setChoosedColumns([...choosedColumns, key]);

      if (!isWhatIfColumn) {
        setSelectedCount((prev) => prev + 1);
      }
    }
  };

  const handleApply = () => {
    let finalColumns = [...choosedColumns];
    if (!finalColumns.includes(primaryColumnKey)) {
      finalColumns.unshift(primaryColumnKey);
    }

    const regularColumnsCount = finalColumns.filter((colKey) => {
      const column = availableColumns.find((col) => col.key === colKey);
      return column && !column.isWhatIf && colKey !== primaryColumnKey;
    }).length;

    if (regularColumnsCount < 5) {
      const additionalColumns = availableColumns
        .filter(
          (col) =>
            !col.isWhatIf &&
            !finalColumns.includes(col.key) &&
            col.key !== primaryColumnKey,
        )
        .slice(0, 5 - regularColumnsCount)
        .map((col) => col.key);

      finalColumns = [...finalColumns, ...additionalColumns];
    }

    onChange(finalColumns);
    setIsModalOpen(false);
  };

  const resetToDefault = () => {
    const defaultColumns = getDefaultColumns();
    setChoosedColumns(defaultColumns);

    const regularColumnsCount = defaultColumns.filter((colKey) => {
      const column = availableColumns.find((col) => col.key === colKey);
      return column && !column.isWhatIf && colKey !== primaryColumnKey;
    }).length;

    setSelectedCount(regularColumnsCount);
  };

  const canDisableColumn = (column: ColumnConfig): boolean => {
    if (column.key === primaryColumnKey) {
      return true;
    }

    if (
      !column.isWhatIf &&
      choosedColumns.includes(column.key) &&
      selectedCount <= 5
    ) {
      return true;
    }

    if (column.isWhatIf && !isWhatIfEnabled) {
      return true;
    }

    return false;
  };

  return (
    <div className={classNames(styles.settingsWrapper, className)}>
      <button
        className={styles.settingsButton}
        onClick={() => setIsModalOpen(true)}
        aria-label="Column settings"
      >
        <Settings size={18} />
      </button>
      <ModalBox
        title={`Column Settings - ${selectedTab}`}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className={styles.modalBox}
      >
        {selectedCount < 5 && (
          <div className={styles.warningMessage}>
            At least 5 regular columns must be selected
          </div>
        )}
        <div className={styles.columnsList}>
          <div className={styles.columnSection}>
            <h4 className={styles.sectionTitle}>Basic Metrics</h4>
            <div className={styles.columnsList}>
              {availableColumns
                .filter((column) => !column.isWhatIf)
                .map((column) => (
                  <div
                    key={column.key}
                    className={classNames(
                      styles.columnItem,
                      canDisableColumn(column) && styles.disabledColumn,
                    )}
                  >
                    <Checkbox
                      onChange={() => toggleColumn(column.key)}
                      checked={choosedColumns.includes(column.key)}
                      className={styles.checkbox}
                      disabled={canDisableColumn(column)}
                    />
                    <p>
                      {column.title}
                      {column.key === primaryColumnKey && (
                        <span className={styles.requiredTag}> (required)</span>
                      )}
                      {!column.isWhatIf &&
                        choosedColumns.includes(column.key) &&
                        selectedCount <= 5 &&
                        column.key !== primaryColumnKey && (
                          <span className={styles.minimumTag}> (minimum)</span>
                        )}
                    </p>
                    {column.info && (
                      <Tooltip title={column.info}>
                        <span className={styles.infoIcon}>i</span>
                      </Tooltip>
                    )}
                  </div>
                ))}
            </div>
          </div>

          {isWhatIfEnabled && (
            <div className={styles.columnSection}>
              <h4 className={styles.sectionTitle}>What-If Metrics</h4>
              {availableColumns
                .filter((column) => column.isWhatIf)
                .map((column) => (
                  <div key={column.key} className={styles.columnItem}>
                    <Checkbox
                      onChange={() => toggleColumn(column.key)}
                      checked={choosedColumns.includes(column.key)}
                      className={styles.checkbox}
                    />
                    <p>{column.title}</p>
                    {column.info && (
                      <Tooltip title={column.info}>
                        <span className={styles.infoIcon}>i</span>
                      </Tooltip>
                    )}
                  </div>
                ))}
            </div>
          )}

          {!isWhatIfEnabled && (
            <div className={styles.whatIfDisabledMessage}>
              Enable "What If" mode to see additional metrics
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <button
            key="reset"
            onClick={resetToDefault}
            className={styles.resetButton}
          >
            Reset to default
          </button>

          <button
            key="apply"
            onClick={handleApply}
            className={styles.applyButton}
          >
            Apply
          </button>
        </div>
      </ModalBox>
    </div>
  );
};
