import { useState } from "react";
import { Tab } from "@pages/Dashboard/features/Widgets/Tab";
import { useTranslation } from "react-i18next";
import { useSuplyStore } from "@pages/Dashboard/store/suplyChain.state";
import styles from "./styles.module.scss";
import { ExecutiveSummary } from "@components/ExecutiveSummary";
import { useCfoStore } from "src/store/cfo.state";
import { BrandAsinSelect } from "@components/brand-asin-select";
import CustomSelect from "@pages/AdsAnalitics/components/Header/ui/CustomSelect";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import CashBlocks from "@pages/SupplyChain/components/CashBlock";
import { PerformanceCard } from "@pages/SupplyChain/components/SalesContribution";
import { newTitles } from "@pages/SupplyChain/components/SupplyChain/utils";
import SupplyBlocks from "@pages/SupplyChain/components/SupplyChain";
import { ActiveView } from "@pages/SupplyChain/components/SalesContribution/mock";
import { ExpandableTable } from "@components/ExpandebleTable";
import { PnlExpandableTable } from "@pages/SupplyChain/components/PnlExpandableTable";
import { financeColumns, financeData } from "./mock";

export function FinanceBlock() {
  const { t } = useTranslation();
  const isRestrictedRoute = location.pathname.includes("finance");
  const { customSuplyWidgets } = useSuplyStore((state) => ({
    customSuplyWidgets: state.customSuplyWidgets,
  }));
  const { dateRange, compareType, setCompareType } = useDashboardStore(
    (state) => ({
      dateRange: state.dateRange,
      compareType: state.compareType,
      setCompareType: state.setCompareType,
    }),
  );
  const { compareWith, setCompareWith } = useAdsAnaliticsStore();
  const { isWhatIf } = useCfoStore();

  const [activeViewSales, setActiveViewSales] = useState<ActiveView>("channel");
  const [activeViewContribution, setActiveViewContribution] =
    useState<ActiveView>("channel");

  const handleByChannelSales = () => {
    setActiveViewSales("channel");
  };
  const handleByAsinSales = () => {
    setActiveViewSales("asin");
  };
  const handleByChannelContribution = () => {
    setActiveViewContribution("channel");
  };
  const handleByAsinContribution = () => {
    setActiveViewContribution("asin");
  };

  const [selections, setSelections] = useState<any[]>([]);
  const rowSelections = {
    selectedRowKeys: selections,
    onChange: (selectedRowKeys: any) => {
      setSelections(selectedRowKeys);
    },
  };

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedKeys((prev) => [...prev, record.key]);
    } else {
      setExpandedKeys((prev) => prev.filter((k) => k !== record.key));
    }
  };

  return (
    <div>
      <header className="dashboard-widgets__header">
        <h1 className={styles.headerTitle}>{t("CFO")}</h1>
        <div className={styles.containerTab}>
          <div className={styles.customSelectContainer}>
            <Tab
              customSelectedWidgets={customSuplyWidgets}
              isRestrictedRoute={isRestrictedRoute}
              isConfigRoute={true}
            />
            <CustomSelect
              dateRange={dateRange}
              compareWith={compareWith}
              setCompareWith={setCompareWith}
              compareType={compareType}
              setCompareType={setCompareType}
              hideSwitch={true}
            />
          </div>
          <div className={styles.brandBlock}>
            {" "}
            <BrandAsinSelect />
          </div>
        </div>
      </header>

      {isWhatIf && (
        <div className={styles.supplyBlock}>
          <h1 className={styles.titleChain}>Supply Chain</h1>
          <SupplyBlocks />
        </div>
      )}

      <div className={styles.executiveBlock}>
        <ExecutiveSummary
          showWhatIf={isWhatIf}
          hideSwitch={true}
          cfoTitles={newTitles}
          alwaysDefault={true}
          hideAllConfig={true}
        />

        <div className={styles.perfomanceBlock}>
          <PerformanceCard
            title="Total Sales"
            activeView={activeViewSales}
            onByChannelClick={handleByChannelSales}
            onByAsinClick={handleByAsinSales}
          />

          <PerformanceCard
            title="Contribution Profit"
            activeView={activeViewContribution}
            onByChannelClick={handleByChannelContribution}
            onByAsinClick={handleByAsinContribution}
          />
        </div>

        <div className={styles.chartBetween}>
          <div className={styles.tableBlock}>
            <h1 className={styles.titleChain}>Financial Overview</h1>
            <PnlExpandableTable />
          </div>
          <div className={styles.financeBlock}>
            <CashBlocks />
          </div>
        </div>
        <div className={styles.costBlock}>
          <h1 className={styles.titleChain}>Product Cost</h1>
          <ExpandableTable
            data={financeData}
            columns={financeColumns}
            withCustomScroll
            rowSelection={rowSelections}
            scroll={{ x: "max-content" }}
            expandable={{
              expandedRowKeys: expandedKeys,
              onExpand: handleExpand,
              rowExpandable: (record) =>
                Array.isArray(record.children) && record.children.length > 0,
            }}
            hideRowSelection={true}
          />
        </div>
      </div>
    </div>
  );
}
