import styles from "src/pages/Auth/Auth.module.scss";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthServices } from "@services/auth.services.ts";
import { AuthProvider } from "src/constants/auth.constants";
import { notification } from "antd";
import { useAuthStore } from "src/store/auth.state";
import { useUserStore } from "src/store/user.state.ts";
import { LS } from "src/constants/localStorage.constants.ts";
import { t } from "i18next";
import { Spinner } from "@components/ui-kit/Spinner";
import { ROUTES } from "src/router/routes";
import { identifyUser } from "src/utils/analytics";

export const VerifyProvider = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const provider = localStorage.getItem(LS.AuthProvider);

    const isGoogle = provider === AuthProvider.Google;
    const isAmazonSeller = provider === AuthProvider.AmazonSellerAccount;
    const isAmazonAds = provider === AuthProvider.AmazonAdsAccount;

    if (isGoogle) {
      const code = searchParams.get("code");
      AuthServices.loginSocial({
        code,
        provider,
      })
        .then((user) => {
          localStorage.setItem(LS.Authenticated, "true");
          useAuthStore.getState().setAuthenticated(true);
          useUserStore.getState().setUser(user);

          if (user && user.email) {
            identifyUser(user.email, user.first_name, user.last_name);
          }

          navigate(ROUTES.ROOT);
        })
        .catch((error) => {
          notification.error({
            message:
              error?.response?.data?.detail ??
              t("message.get_user_fail_message"),
          });
        });
    } else if (isAmazonSeller) {
      const payload = {
        state: searchParams.get("state"),
        selling_partner_id: searchParams.get("selling_partner_id"),
        spapi_oauth_code: searchParams.get("spapi_oauth_code"),
      };

      AuthServices.connectAmazonSellerAccount(payload)
        .then((user) => {
          localStorage.removeItem(LS.AuthProvider);
          localStorage.removeItem(LS.AmazonOnboardingStatus);
          useUserStore.getState().setUser(user);
          navigate(`/${ROUTES.AMAZON_ONBOARDING_COMPLETE}`);
        })
        .catch((error) => {
          notification.error({
            message:
              error?.response?.data?.detail ??
              t("message.get_user_fail_message"),
          });
        });
    } else if (isAmazonAds) {
      const code = searchParams.get("code");

      AuthServices.loginSocial({
        code,
        provider,
      })
        .then((user) => {
          localStorage.setItem(LS.Authenticated, "true");
          useAuthStore.getState().setAuthenticated(true);
          useUserStore.getState().setUser(user);

          if (user && user.email) {
            identifyUser(user.email, user.first_name, user.last_name);
          }

          navigate(`/${ROUTES.CONGRATULATIONS_CONNECT}`, { replace: true });
        })
        .catch((error) => {
          notification.error({
            message:
              error?.response?.data?.detail ??
              t("message.get_user_fail_message"),
          });
          navigate(ROUTES.ROOT, { replace: true });
        });
    }
  }, [searchParams, navigate]);

  return <Spinner size="xxl" className={styles.authPageVerificationSpinner} />;
};
