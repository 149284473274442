export enum AnalyticsEventName {
  PAGE_VIEW = "pageview",
  BUTTON_CLICK = "button_click",
  IDENTIFY = "identify",
  SIGNUP_ATTEMPT = "signup_attempt",
  SIGNUP_SUCCESS = "signup_success",
  LOGIN = "login",
  LOGOUT = "logout",
  PURCHASE = "purchase",
  SEARCH = "search",
  SHARE = "share",
  ERROR = "error",
}

export interface IAnalyticsEvent {
  event: AnalyticsEventName;
  parameters: Record<string, any>;
}

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
