import React, { useState } from "react";
import CalendarImage from "@assets/icons/calendar.svg";
import { CalendarModal } from "@components/calendar-modal";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { initializeCalendarData } from "src/utils/initialCalendarData";
import { formatDateRange } from "src/utils/formatDate";
import { useBackground } from "@components/BackfroundContext";

const CalendarButton: React.FC = () => {
  const { setIsBlurred } = useBackground();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });

  const { today, monday } = initializeCalendarData();
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: monday,
    endDate: today,
  });

  const modalRef = useOutsideClick(() => {
    setIsCalendarOpen(false);
    setIsBlurred(false);
  });

  const toggleCalendar = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (
      event.type === "click" ||
      (event as React.KeyboardEvent).key === "Enter" ||
      (event as React.KeyboardEvent).key === " "
    ) {
      event.stopPropagation();
      setIsCalendarOpen((prevState) => {
        setIsBlurred(!prevState);
        return !prevState;
      });

      if (!isCalendarOpen && event.currentTarget instanceof HTMLElement) {
        const buttonRect = event.currentTarget.getBoundingClientRect();
        setModalPosition({
          x: buttonRect.right,
          y: buttonRect.bottom,
          width: buttonRect.width,
        });
      }
    }
  };

  const handleDateChange = (startDate: Date, endDate: Date) => {
    setDateRange({ startDate, endDate });
    setIsCalendarOpen(false);
    setIsBlurred(false);
  };

  return (
    <div>
      <div className={styles.bg}></div>
      <button
        onClick={toggleCalendar}
        onKeyDown={toggleCalendar}
        className={classNames(styles.element, styles.calendarButton, {
          [styles["element--active"]]: isCalendarOpen,
        })}
      >
        <img src={CalendarImage} alt="Calendar" className={styles.icon} />
        <p className={styles.tabItem}>
          {formatDateRange(dateRange.startDate, dateRange.endDate)}
        </p>
      </button>
      {isCalendarOpen && (
        <button
          ref={modalRef}
          className={styles.modalContainer}
          onClick={(e) => e.stopPropagation()}
        >
          <CalendarModal
            buttonWidth={modalPosition.width}
            closeModal={() => {
              setIsCalendarOpen(false);
              setIsBlurred(false);
            }}
            onDateChange={handleDateChange}
            initialRange={dateRange}
          />
        </button>
      )}
    </div>
  );
};

export default CalendarButton;
