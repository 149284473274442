export interface ColumnConfig {
  title: string;
  dataIndex: string;
  key: string;
  width: number;
  fixed?: "left";
  info?: string;
}

export type TableType =
  | "performance"
  | "targeting"
  | "match"
  | "targetType"
  | "campaigns"
  | "adType"
  | "funnel";

export const baseMetricsColumns: ColumnConfig[] = [
  {
    title: "Ad Impressions",
    dataIndex: "ad_impressions",
    key: "ad_impressions",
    width: 140,
  },
  {
    title: "Ad Clicks",
    dataIndex: "ad_clicks",
    key: "ad_clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ad_ctr",
    key: "ad_ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "Ad Units Solds",
    dataIndex: "ad_units_sold",
    key: "ad_units_sold",
    width: 140,
  },
  {
    title: "Ad CVR",
    dataIndex: "ad_cvr",
    key: "ad_cvr",
    width: 140,
  },
  {
    title: "Ad Sales",
    dataIndex: "ad_sales",
    key: "ad_sales",
    width: 140,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 140,
  },
];

export const whatIfColumns: ColumnConfig[] = [
  {
    title: "Ad Impressions What If",
    dataIndex: "ad_impressions_what_if",
    key: "ad_impressions_what_if",
    width: 200,
  },
  {
    title: "Ad Spend What If",
    dataIndex: "ad_spend_what_if",
    key: "ad_spend_what_if",
    width: 180,
  },
  {
    title: "CPC What If",
    dataIndex: "cpc_what_if",
    key: "cpc_what_if",
    width: 140,
  },
  {
    title: "Ad Sales What If",
    dataIndex: "ad_sales_what_if",
    key: "ad_sales_what_if",
    width: 180,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_what_if",
    key: "acos_what_if",
    width: 180,
  },
  {
    title: "ROAS What If",
    dataIndex: "roas_what_if",
    key: "roas_what_if",
    width: 180,
  },
];

const firstColumnConfig: Record<TableType, ColumnConfig> = {
  performance: {
    title: "Type Ad",
    dataIndex: "performance",
    key: "performance",
    width: 400,
    fixed: "left",
  },
  targeting: {
    title: "Targeting",
    dataIndex: "target",
    key: "target",
    width: 256,
    fixed: "left",
  },
  match: {
    title: "Match Type",
    dataIndex: "match_type",
    key: "match_type",
    width: 256,
    fixed: "left",
  },
  targetType: {
    title: "Target Type",
    dataIndex: "target",
    key: "target",
    width: 256,
    fixed: "left",
  },
  campaigns: {
    title: "Campaign",
    dataIndex: "campaign_name",
    key: "campaign_name",
    width: 278,
    fixed: "left",
  },
  adType: {
    title: "Ad Type",
    dataIndex: "ad_type",
    key: "ad_type",
    width: 278,
    fixed: "left",
  },
  funnel: {
    title: "Funnel",
    dataIndex: "funnel",
    key: "funnel",
    width: 278,
    fixed: "left",
  },
};

export const createTableColumns = (
  type: TableType,
  includeWhatIf: boolean = true,
): ColumnConfig[] => {
  const columns = [firstColumnConfig[type], ...baseMetricsColumns];

  return includeWhatIf ? [...columns, ...whatIfColumns] : columns;
};

export const performanceColumns = createTableColumns("performance");
export const targetingColumns = createTableColumns("targeting");
export const matchColumns = createTableColumns("match");
export const targetTypeColumns = createTableColumns("targetType");
export const campaignsColumns = createTableColumns("campaigns");
export const adTypeColumns = createTableColumns("adType");
export const funnelColumns = createTableColumns("funnel");

export const performanceColumnsWithoutNyle = createTableColumns(
  "performance",
  false,
);
export const targetingColumnsWithoutNyle = createTableColumns(
  "targeting",
  false,
);
export const matchColumnsWithoutNyle = createTableColumns("match", false);
export const targetTypeColumnsWithoutNyle = createTableColumns(
  "targetType",
  false,
);
export const campaignsColumnsWithoutNyle = createTableColumns(
  "campaigns",
  false,
);
export const adTypeColumnsWithoutNyle = createTableColumns("adType", false);
export const funnelColumnsWithoutNyle = createTableColumns("funnel", false);
