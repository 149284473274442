import {
  DatePeriod,
  InventoryPerformanceApi,
  InventoryHistoryResponse,
} from "./inventory-performance.api";
import { format, parseISO } from "date-fns";

interface ChartDataPoint {
  date: string;
  [key: string]: number | string;
}

export class InventoryPerformanceService {
  private api: InventoryPerformanceApi;

  constructor() {
    this.api = new InventoryPerformanceApi();
  }

  public async fetchMetricData(
    metric: string,
    startDate: string,
    endDate: string,
    period?: DatePeriod,
    asinFilter?: string[],
    brandFilter?: string[],
  ): Promise<number[]> {
    try {
      const response = await this.api.getInventoryHistory(
        startDate,
        endDate,
        period,
        asinFilter,
        brandFilter,
      );

      const dates = Object.keys(response).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime(),
      );

      switch (metric) {
        case "availableCapital":
          return dates.map((date) => {
            const dayData = response[date];
            if (!dayData || dayData.length === 0 || !dayData[0].metric) {
              return 0;
            }

            const availableMetric = dayData[0].metric.find(
              (metric) => metric.name === "AVAILABLE",
            );

            return availableMetric?.units || 0;
          });

        case "unavailableUnits":
          return dates.map((date) => {
            const dayData = response[date];
            if (!dayData || dayData.length === 0 || !dayData[0].metric) {
              return 0;
            }

            const unavailableMetric = dayData[0].metric.find(
              (metric) => metric.name === "UNAVAILABLE",
            );

            return unavailableMetric?.units || 0;
          });

        default:
          return this.generateMockData(startDate, endDate);
      }
    } catch (error) {
      console.error(`Error fetching ${metric}:`, error);
      return this.generateMockData(startDate, endDate);
    }
  }

  private generateMockData(startDate: string, endDate: string): number[] {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const days = Math.ceil(
      (end.getTime() - start.getTime()) / (1000 * 3600 * 24),
    );

    return Array(days)
      .fill(0)
      .map(() => Math.floor(Math.random() * 2000));
  }

  public async getChartData(
    metrics: string[],
    startDate: Date,
    endDate: Date,
    period?: DatePeriod,
    asinFilter?: string[],
    brandFilter?: string[],
  ): Promise<ChartDataPoint[]> {
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    const metricsData = await Promise.all(
      metrics.map((metric) =>
        this.fetchMetricData(
          metric,
          formattedStartDate,
          formattedEndDate,
          period,
          asinFilter,
          brandFilter,
        ),
      ),
    );

    const dates = this.generateDateRange(startDate, endDate);

    return dates.map((date, index) => {
      const dataPoint: ChartDataPoint = { date };
      metrics.forEach((metric, mIndex) => {
        dataPoint[metric] = metricsData[mIndex][index] || 0;
      });
      return dataPoint;
    });
  }

  private generateDateRange(start: Date, end: Date): string[] {
    const dates: string[] = [];
    const current = new Date(start);

    while (current <= end) {
      dates.push(format(current, "MMM dd"));
      current.setDate(current.getDate() + 1);
    }

    return dates;
  }

  /**
   * Processes raw inventory data from API response to extract specific metrics
   */
  public extractMetricFromResponse(
    response: InventoryHistoryResponse,
    metricName: string,
    valueType: "units" | "revenue" = "units",
  ): { date: string; value: number }[] {
    return Object.entries(response)
      .map(([date, dayData]) => {
        let value = 0;

        if (dayData && dayData.length > 0 && dayData[0].metric) {
          const targetMetric = dayData[0].metric.find(
            (metric) => metric.name === metricName,
          );

          if (targetMetric) {
            value =
              valueType === "units"
                ? targetMetric.units
                : targetMetric.revenue?.amount || 0;
          }
        }

        return {
          date: format(parseISO(date), "MMM dd"),
          value,
        };
      })
      .sort((a, b) => {
        // Ensure dates are sorted chronologically
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
  }
}
