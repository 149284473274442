import "./index.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { FC } from "react";
import classNames from "classnames";
import { WidgetClass } from "../../../../../services/widget.service";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { WidgetChart } from "./WidgetChart";

interface IProps {
  widget: IWidget;
  additionalClass?: string;
  formatDiff?: boolean;
}

export const Widget: FC<IProps> = ({
  widget: { key, name, difference, value, chartData },
  additionalClass,
  formatDiff = true,
}) => {
  const { compareType: typeToCompare } = useDashboardStore();

  const widgetClass = new WidgetClass();
  const differenceClass = classNames(
    "dashboard-widget__difference",
    "text-button",
    difference[0] === "-"
      ? "dashboard-widget__difference--negative"
      : difference[0] === "+"
        ? "dashboard-widget__difference--positive"
        : "",
  );

  const getDifference = (diff: string) => {
    if (formatDiff) {
      if (typeToCompare === "raw") {
        return widgetClass.formatValue(key, Number(diff));
      }
      return diff;
    }
    return diff;
  };

  return (
    <div className={classNames("dashboard-widget", additionalClass)}>
      <header className="dashboard-widget__header">
        <span className={classNames("dashboard-widget__name", "heading-3")}>
          {name}
        </span>
        <span className={differenceClass}>{getDifference(difference)}</span>
      </header>
      <footer className="dashboard-widget__footer">
        <span className={classNames("dashboard-widget__value", "body-large")}>
          {widgetClass.formatValue(key, Number(value))} <br />
        </span>
      </footer>
      {chartData && (
        <div className="dashboard-widget__chart">
          <WidgetChart chartData={chartData} />
        </div>
      )}
    </div>
  );
};
