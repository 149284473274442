import React, { useState } from "react";
import { Card, Tooltip } from "antd";
import styles from "./styles.module.scss";
import Setting from "@assets/icons/setting";
import { initialChecks } from "./utils";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { WidgetClass } from "@services/widget.service";
import { SalesPerformanceProps } from "@services/sales-performance/sales-performance.types";
import { useSalesPerformanceChart } from "@services/sales-performance/useSalesPerformance";
import { BarChart } from "./components/BarChart";
import { LineChart } from "./components/LineChart";
import { SettingsModal } from "./components/SettingsModal";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { useIsMobile } from "src/utils/isMobile";
import classNames from "classnames";
import { useAdvancedViewStore } from "src/store/advancedView";

const SalesPerformance: React.FC<SalesPerformanceProps> = ({
  choosedGroups = "all",
  hideBg = false,
}) => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);

  const { isAdvancedView, setIsAdvancedView } = useAdvancedViewStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [checks, setChecks] = useState(initialChecks);
  const [isForecasted, setIsForecasted] = useState(false);
  const isMobile = useIsMobile();
  const { dateRange } = useDashboardStore();
  const { data, metricsColors } = useSalesPerformanceChart(dateRange, checks);

  const activeChecks = Object.entries(checks)
    .filter(([_, isChecked]) => isChecked)
    .map(([key]) => key);

  return (
    <Card
      className={classNames(styles.cardPerfomance, {
        [styles.cardPerfomanceBg]: !hideBg,
      })}
      bodyStyle={{ padding: isMobile ? "0" : "5px" }}
    >
      <div className={styles.headerBetween}>
        <h1 className={styles.titleSales}>Sales Performance</h1>
        <button
          className={styles.setting__button}
          onClick={() => setIsOpenModal(true)}
        >
          <Setting />
        </button>
        <SettingsModal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          isAdvancedView={isAdvancedView}
          handleToggleView={setIsAdvancedView}
          checks={checks}
          setChecks={setChecks}
          isForecasted={isForecasted}
          setIsForecasted={setIsForecasted}
          choosedGroups={choosedGroups}
        />
      </div>

      <div className={styles.legendContainer}>
        {Object.entries(checks).map(
          ([key, isChecked]) =>
            isChecked && (
              <div key={key} className={styles.legendItem}>
                <span
                  className={styles.colorDot}
                  style={{ backgroundColor: metricsColors[key] }}
                />
                <span className={styles.legendText}>
                  {WidgetClass.convertKeyToName(key)}
                </span>
                <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info}>
                  <button>i</button>
                </Tooltip>
              </div>
            ),
        )}
      </div>

      {!isAdvancedView ? (
        <LineChart
          data={data}
          metrics={activeChecks}
          metricsColors={metricsColors}
          isForecasted={isForecasted}
        />
      ) : (
        <BarChart
          dataSet={data}
          activeLabel={activeLabel}
          setActiveLabel={setActiveLabel}
          metrics={activeChecks}
          metricsColors={metricsColors}
          isForecasted={isForecasted}
        />
      )}
    </Card>
  );
};

export default SalesPerformance;
