import { widgetService } from "@services/widget.service";

const renderMetricItem = (label: string, value: number, key: string) => {
  if (value === undefined || value === null) return "";

  return `
    <div style="display: flex; flex-direction: column; gap: 4px;">
      <span style="color: #5C5C5A;">${label}</span>
      <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
        <strong>${widgetService.formatValue(key, value)}</strong>
      </div>
    </div>
  `;
};

const adMetrics = [
  { label: "Impressions", key: "adImpressions", apiKey: "ad_impressions" },
  { label: "Clicks", key: "adClicks", apiKey: "ad_clicks" },
  { label: "Units Sold", key: "adUnitsSold", apiKey: "ad_units_sold" },
  { label: "Orders", key: "adOrders", apiKey: "ad_orders" },
  { label: "Sales", key: "adSales", apiKey: "ad_sales" },
  { label: "Spend", key: "adSpend", apiKey: "ad_spend" },
  { label: "ACOS", key: "adAcos", apiKey: "acos" },
  { label: "ROAS", key: "adRoas", apiKey: "roas" },
  { label: "CPC", key: "adCpc", apiKey: "cpc" },
  { label: "CAC", key: "adCac", apiKey: "cac" },
  { label: "CTR", key: "adCtr", apiKey: "ad_ctr" },
  { label: "CVR", key: "adCvr", apiKey: "ad_cvr" },
];

const organicMetrics = [
  {
    label: "Organic Impressions",
    key: "organicImpressions",
    apiKey: "organic_impressions",
  },
  { label: "Organic Clicks", key: "organicClicks", apiKey: "organic_clicks" },
  {
    label: "Organic Units Sold",
    key: "organicUnitsSold",
    apiKey: "organic_units_sold",
  },
  { label: "Organic Orders", key: "organicOrders", apiKey: "organic_orders" },
  { label: "Organic Sales", key: "organicSales", apiKey: "organic_sales" },
  { label: "Organic CTR", key: "organicCtr", apiKey: "organic_ctr" },
  { label: "Organic CVR", key: "organicCvr", apiKey: "organic_cvr" },
  { label: "TACOS", key: "tacos", apiKey: "tacos" },
];

export const tooltipFormatter = async (
  params: any,
  tooltipX?: number,
  dateRange?: { startDate: Date; endDate: Date },
  apiMetrics?: Record<string, number>,
) => {
  if (!dateRange) {
    console.error("Date range is required for tooltip metrics");
    return "";
  }

  const [_, value, seriesName] = params.data;

  const isForecast = seriesName.startsWith("Forecast ");
  const baseSeriesName = isForecast
    ? seriesName.replace("Forecast ", "")
    : seriesName;

  try {
    let supTitle = "(Rest of Search)";

    if (tooltipX < 420) {
      supTitle = "(First page Top of search)";
    }

    if (tooltipX > 420 && tooltipX < 840) {
      supTitle = "(First page Bottom of search)";
    }

    if (baseSeriesName === "Amazon Organic") {
      const displayName = isForecast
        ? "Amazon Organic (What If)"
        : "Amazon Organic";

      const suffix = isForecast ? "_what_if" : "";

      const metricsHtml = organicMetrics
        .map((metric) => {
          let value;

          if (metric.key === "organicCtr") {
            const apiKey = `organic_ctr${suffix}`;
            value =
              apiMetrics[apiKey] !== undefined
                ? apiMetrics[apiKey]
                : widgetService.generateRandomValueForKey(metric.key);
          } else if (metric.key === "organicCvr") {
            const apiKey = `organic_cvr${suffix}`;
            value =
              apiMetrics[apiKey] !== undefined
                ? apiMetrics[apiKey]
                : widgetService.generateRandomValueForKey(metric.key);
          } else {
            const apiKey = `${metric.apiKey}${suffix}`;
            value =
              apiMetrics[apiKey] !== undefined
                ? apiMetrics[apiKey]
                : widgetService.generateRandomValueForKey(metric.key);
          }

          return renderMetricItem(metric.label, value, metric.key);
        })
        .join("");

      return `
        <div style="font-size: 14px; color: #2E2E2E; display: flex; flex-direction: column; gap: 12px;">
          <div style="display: flex; flex-direction: row; gap: 4px;">
            <strong style="font-size: 16px;">${displayName}</strong> 
            <span>${supTitle}</span>
          </div>
          <div style="display: flex; flex-direction: row; gap: 24px;">
            <div style="display: grid; grid-template-columns: repeat(6, 1fr); gap: 16px; width: 600px;">
              ${metricsHtml}
            </div>
          </div>
        </div>
      `;
    } else if (baseSeriesName === "Amazon Ads") {
      const displayName = isForecast ? "Amazon Ads (What If)" : "Amazon Ads";

      const suffix = isForecast ? "_what_if" : "";

      const metricsHtml = adMetrics
        .map((metric) => {
          const apiKey = `${metric.apiKey}${suffix}`;
          const value =
            apiMetrics[apiKey] !== undefined
              ? apiMetrics[apiKey]
              : widgetService.generateRandomValueForKey(metric.key);

          return renderMetricItem(metric.label, value, metric.key);
        })
        .join("");

      return `
        <div style="font-size: 14px; color: #2E2E2E; display: flex; flex-direction: column; gap: 12px;">
          <strong style="font-size: 16px;">${displayName}</strong> 
          <div style="display: flex; flex-direction: row; gap: 24px;">
            <div style="display: grid; grid-template-columns: repeat(6, 1fr); gap: 16px; width: 600px;">
              ${metricsHtml}
            </div>
          </div>
        </div>
      `;
    } else {
      return `
        <div style="font-size: 14px; color: #2E2E2E; padding: 10px;">
          <div style="display: flex; flex-direction: row; gap: 4px;">
            <strong style="font-size: 16px;">${seriesName}</strong>
          </div>
          <div style="margin-top: 8px;">Value: ${value.toLocaleString()}</div>
        </div>
      `;
    }
  } catch (error) {
    console.error("Error formatting tooltip:", error);
    return `<div>Error displaying data</div>`;
  }
};
