import { ArrowDown, ArrowUp } from "lucide-react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import classNames from "classnames";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { getProfitColor, formatName } from "./utils";
import { useIsMobile } from "src/utils/isMobile";
import { useMemo } from "react";
import { WidgetClass } from "@services/widget.service";

interface MetricCardProps {
  metric: IWidget;
  metricColor?: string;
  goalValue?: number;
  maxValue?: number;
}

const areNumbersEqual = (a: number, b: number, epsilon = 0.0001): boolean => {
  return Math.abs(a - b) < epsilon;
};

export const MetricCard = ({
  metric,
  metricColor,
  goalValue,
  maxValue,
}: MetricCardProps) => {
  const widgetClass = useMemo(() => new WidgetClass(), []);
  const { adSlides } = useAdsAnaliticsStore((state) => ({
    adSlides: state.adSlides,
  }));
  const isMobile = useIsMobile();

  const standardValue = Number(metric.originalValue);
  const whatIfValue = metric.whatIfValue || standardValue;

  const hasWhatIfDifference = !areNumbersEqual(whatIfValue, standardValue);

  const isWhatIfPositive = whatIfValue > standardValue;

  let metricValue = Number(metric.originalValue);
  let whatIfMetricValue = whatIfValue;

  const maxMetricValue =
    maxValue || goalValue
      ? Math.max(goalValue, metricValue, whatIfMetricValue) * 1.6
      : Math.max(metricValue, whatIfMetricValue) * 1.4;

  let color = metricColor;
  let whatIfColor = isWhatIfPositive ? "#80C67A" : "#FE5858";

  const pieWidth = isMobile ? 165 : 190;
  const strokeWidth = isMobile ? 13 : 30;

  const isForecastNeeded = Object.values(adSlides).some((value) => value !== 0);

  const profit =
    typeof metric?.difference === "string"
      ? metric.difference.startsWith("+")
      : Number(metric?.difference) > 0;
  if (!color) {
    color = profit ? "#80C67A" : "#FE5858";
  }

  if (isForecastNeeded) {
    const forecastSumRaw = Object.values(adSlides).reduce(
      (acc, value) => acc + value,
      0,
    );

    const multiplier = metricValue / 100;
    const forecastSum = forecastSumRaw * multiplier;

    metricValue = metricValue + forecastSum;

    const whatIfMultiplier = whatIfMetricValue / 100;
    const whatIfForecastSum = forecastSumRaw * whatIfMultiplier;

    whatIfMetricValue = whatIfMetricValue + whatIfForecastSum;

    color = getProfitColor(metric.name, metricValue, maxMetricValue);
    const shouldInvertColor =
      metric.key?.toLowerCase().includes("acos") ||
      metric.key?.toLowerCase().includes("cpc") ||
      metric.key?.toLowerCase().includes("cac");

    if (shouldInvertColor) {
      whatIfColor = isWhatIfPositive ? "#FE5858" : "#80C67A";
    } else {
      whatIfColor = isWhatIfPositive ? "#80C67A" : "#FE5858";
    }
  }

  return (
    <div
      className={classNames(
        styles.metricCard,
        !profit && styles.metricCardSmall,
      )}
    >
      <div className={styles.metricHeader}>{formatName(metric.name)}</div>
      <div className={styles.metricBody}>
        <PieChart
          width={pieWidth}
          height={pieWidth / 2}
          className={styles.metricPie}
        >
          <Pie
            data={[{ value: 100 }]}
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={
              hasWhatIfDifference || goalValue > 0
                ? isMobile
                  ? 65
                  : 80
                : pieWidth / 2 - strokeWidth
            }
            outerRadius={
              hasWhatIfDifference || goalValue > 0
                ? isMobile
                  ? 75
                  : 95
                : pieWidth / 2
            }
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
            fill={"#2E2E2E"}
            stroke="none"
          ></Pie>

          <Pie
            data={[{ value: metricValue }, { value: maxMetricValue }]}
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={
              hasWhatIfDifference || goalValue > 0
                ? isMobile
                  ? 65
                  : 80
                : pieWidth / 2 - strokeWidth
            }
            outerRadius={
              hasWhatIfDifference || goalValue > 0
                ? isMobile
                  ? 75
                  : 95
                : pieWidth / 2
            }
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
            opacity={hasWhatIfDifference || goalValue > 0 ? 0.6 : 1}
          >
            <Cell
              fill={hasWhatIfDifference || goalValue > 0 ? color : color}
              stroke="none"
            />
            <Cell fill="none" stroke="none" />
          </Pie>

          {(hasWhatIfDifference || goalValue > 0) && (
            <Pie
              data={[{ value: 100 }]}
              cx="50%"
              cy="90"
              startAngle={180}
              endAngle={0}
              innerRadius={isMobile ? 55 : 65}
              outerRadius={isMobile ? 65 : 80}
              paddingAngle={0}
              dataKey="value"
              cornerRadius={32}
              fill={"#2E2E2E"}
              stroke="none"
            ></Pie>
          )}

          {hasWhatIfDifference && (
            <Pie
              data={[{ value: whatIfMetricValue }, { value: maxMetricValue }]}
              cx="50%"
              cy="90"
              startAngle={180}
              endAngle={0}
              innerRadius={isMobile ? 55 : 65}
              outerRadius={isMobile ? 65 : 80}
              paddingAngle={0}
              dataKey="value"
              cornerRadius={32}
            >
              <Cell fill={whatIfColor} stroke="none" />
              <Cell fill="none" stroke="none" />
            </Pie>
          )}

          {goalValue > 0 && !hasWhatIfDifference && (
            <Pie
              data={[{ value: goalValue }, { value: maxMetricValue }]}
              cx="50%"
              cy="90"
              startAngle={180}
              endAngle={0}
              innerRadius={isMobile ? 55 : 65}
              outerRadius={isMobile ? 65 : 80}
              paddingAngle={0}
              dataKey="value"
              cornerRadius={32}
            >
              <Cell fill={color} stroke="none" />
              <Cell fill="none" stroke="none" />
            </Pie>
          )}
        </PieChart>

        <div className={styles.metricValuesBox}>
          {hasWhatIfDifference && (
            <div className={styles.whatIf}>
              <div className={styles.whatIf}>
                <span
                  className={styles.whatIfValue}
                  style={{ color: whatIfColor }}
                >
                  {widgetClass.formatValue(metric.key, whatIfValue)}
                </span>
              </div>
              <h4>
                Current:{" "}
                {widgetClass.formatValue(
                  metric.key,
                  Number(metric.originalValue),
                )}
              </h4>
            </div>
          )}

          {goalValue > 0 && !hasWhatIfDifference && (
            <div className={styles.metricValue}>
              {widgetClass.formatValue(metric.key, goalValue)}
            </div>
          )}

          {!hasWhatIfDifference && (
            <h4
              className={classNames(
                styles.metricValue,
                goalValue > 0 && styles.withGoals,
              )}
            >
              {goalValue > 0 && "Current: "}
              {widgetClass.formatValue(
                metric.key,
                Number(metric.originalValue),
              )}
            </h4>
          )}

          {metric.difference &&
            metric.difference !== "0" &&
            !hasWhatIfDifference && (
              <div className={styles.metricChange} style={{ color }}>
                {widgetClass.formatValue(
                  metric.key,
                  Number(metric?.difference),
                )}
                {profit ? <ArrowUp size={12} /> : <ArrowDown size={12} />}
              </div>
            )}
          {hasWhatIfDifference && (
            <div
              className={styles.metricChange}
              style={{
                color: whatIfColor,
              }}
            >
              {(isWhatIfPositive ? "+" : "") +
                (((whatIfValue - standardValue) / standardValue) * 100).toFixed(
                  1,
                ) +
                "%"}
              {isWhatIfPositive ? (
                <ArrowUp size={12} />
              ) : (
                <ArrowDown size={12} />
              )}
            </div>
          )}
        </div>

        <div className={styles.metricCircle}>
          <svg viewBox="0 0 100 52" version="1.1">
            <circle
              cx="50px"
              cy="50px"
              r="48"
              stroke="#C8A857"
              strokeWidth="2"
              strokeDasharray="0 10"
              strokeLinecap="round"
              fill="transparent"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
