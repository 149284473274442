import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import CalendarImage from "@assets/icons/calendar.svg";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { formatDateRange } from "src/utils/formatDate";
import { t } from "i18next";
import { CalendarModal } from "@components/calendar-modal";
import CustomSelect from "@pages/AdsAnalitics/components/Header/ui/CustomSelect";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { Navigation } from "@pages/AdsAnalitics/components/Header/ui/navigation";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { ExecutiveSummary } from "@components/ExecutiveSummary";
import { useBackground } from "@components/BackfroundContext";
import { generateDateRangeArray } from "@components/SalesPerformance/utils";
import { Checks } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { initialCheck } from "@pages/Dashboard/features/SalesPerformance/types";
import BrandRevenueChart from "./BrandRevenueChart";
import MarketOverview from "./DotChart";
import PerformanceBlock from "./BrandBlock";
import MarketLevelSalesDistribution from "./MarketLevelBlock";
import { useMarketStore } from "src/store/market.state";
import { TotalSales } from "./TotalSales";
import { BrandPerformance } from "./BrandPerformance";
import { Products } from "./Products";
import { BrandTable } from "./BrandTable";

export const MarketTitles = [
  "Market Total Sales",
  "Brand Market Share",
  "Market Average Price",
  "Market Units Sold",
  "Market ASIN count",
  "Market Promotion Value",
  "Market Promotion Count",
  "Market Review Score",
  "Market POS",
  "Market Ad Spend",
];

export type Option = {
  id: string;
  text: string;
  img?: string;
  asin?: string;
};

export const COLORSCHART = ["#fc6762", "#6B4DBA", "#5295E0"];

export function MarketIntelligenceBlock() {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataSet, setDataSet] = useState<any[]>([]);
  const { setIsBlurred } = useBackground();
  const { dateRange, setDateRange, compareType, setCompareType } =
    useDashboardStore((state) => ({
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      compareType: state.compareType,
      setCompareType: state.setCompareType,
    }));
  const { setAsinBrandSelect } = useDashboardStore();
  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );
  const { compareWith, setCompareWith } = useAdsAnaliticsStore();
  const [modalChecks, setModalChecks] = useState<Checks>(
    initialCheck as Checks,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isWhatIf } = useMarketStore();
  const handleDateChange = (startDate: Date, endDate: Date) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
    setIsBlurred(false);
  };

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat("en", {
        day: "numeric",
        month: "short",
      }),
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await getData(dateRange, modalChecks);
        const transformedData = transformDataToObject(fetchedData);
        setData(transformedData);
        const salesData: number[] = fetchedData.sales || [];
        const allDates = generateDateRangeArray(
          dateRange.startDate,
          dateRange.endDate,
        );
        const mappedData = allDates.map((dateObj, index) => {
          const dateStr = dateFormatter.format(dateObj);
          return {
            date: dateStr,
            AdsSales: salesData[index] || 0,
            OrganicSales: Array.isArray(fetchedData.OrganicSales)
              ? fetchedData.OrganicSales[index] || 0
              : 0,
          };
        });
        setDataSet(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (
      dateRange &&
      dateRange.startDate &&
      dateRange.endDate &&
      Object.values(modalChecks).some((value) => value)
    ) {
      fetchData();
    }
  }, [dateRange, modalChecks, dateFormatter]);

  const label = ["Market Share", "Metrics Rate", "ASIN Query Rate"];

  useEffect(() => {
    setAsinBrandSelect(false);
  }, []);

  return (
    <div>
      <div className={styles.dashboardWidgetsHeader}>
        <div className={styles.navBetween}>
          <Navigation customTitles={label} setIsOpenMenu={setIsMenuOpen} />
          <h1 className={styles.headerTitle}>{t("Market Intelligence")}</h1>
        </div>
        <div className={styles.buttons}>
          <div className={styles.calendar}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsCalendarOpen(!isCalendarOpen);
                setIsBlurred(!isCalendarOpen);
              }}
              className={classNames(
                styles.button,
                isCalendarOpen && styles.active,
              )}
            >
              <img src={CalendarImage} alt="Calendar" />
              {selectedDateRange}
            </button>
            {isCalendarOpen && (
              <div className={styles.modal}>
                <CalendarModal
                  buttonWidth={148}
                  closeModal={() => {
                    setIsCalendarOpen(false);
                    setIsBlurred(false);
                  }}
                  onDateChange={handleDateChange}
                  initialRange={dateRange}
                />
              </div>
            )}
          </div>
          <CustomSelect
            dateRange={dateRange}
            compareWith={compareWith}
            setCompareWith={setCompareWith}
            compareType={compareType}
            setCompareType={setCompareType}
            hideSwitch={true}
          />
        </div>
      </div>
      <div className={styles.executiveSummary}>
        <ExecutiveSummary
          hideSwitch={true}
          titles={MarketTitles}
          showWhatIf={true}
          brand={true}
        />
      </div>
      <div className={styles.marketLevel}>
        <MarketLevelSalesDistribution />
        {/* <MarketSizeChart
          data={data}
          modalChecks={modalChecks}
          setModalChecks={setModalChecks}
        /> */}
      </div>
      <div className={styles.marketBetween}>
        <div className={styles.brandRevenue}>
          <MarketOverview />
        </div>
        <div className={styles.brandRevenue}>
          <BrandRevenueChart hidePercentage={true} />
        </div>
      </div>
      <div className={styles.additionalChartsContainer}>
        <PerformanceBlock />
        <BrandTable isWhatIf={isWhatIf} />
      </div>
      <BrandPerformance />
      <TotalSales />
      <Products />
    </div>
  );
}
