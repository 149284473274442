import { useState, useEffect } from "react";

export function useIsMobile(customBreakpoint: number = 768) {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < customBreakpoint,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < customBreakpoint);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [customBreakpoint]);

  return isMobile;
}
