import { AnalyticsEventName, IAnalyticsEvent } from "../types/analytics";

class AnalyticsEvent implements IAnalyticsEvent {
  event: AnalyticsEventName;
  parameters: Record<string, any>;

  constructor(event: AnalyticsEventName, parameters: Record<string, any> = {}) {
    this.event = event;
    this.parameters = parameters;
  }
}

const pushToDataLayer = (data: object) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  }
};

const convertToDataLayer = (event: IAnalyticsEvent): Record<string, any> => {
  return {
    event: event.event,
    parameters: event.parameters,
  };
};

export const trackPageView = (path: string, title: string) => {
  const pageViewEvent = new AnalyticsEvent(AnalyticsEventName.PAGE_VIEW, {
    path,
    title,
  });
  trackEvent(pageViewEvent);
};

export const trackButtonClick = (
  buttonPagePath: string,
  buttonId: string,
  buttonText: string,
) => {
  const buttonEvent = new AnalyticsEvent(AnalyticsEventName.BUTTON_CLICK, {
    button_page_path: buttonPagePath,
    button_id: buttonId,
    button_text: buttonText,
  });
  trackEvent(buttonEvent);
};

export const identifyUser = (
  email: string,
  firstName?: string,
  lastName?: string,
) => {
  console.log(`Identifying user: ${email}, ${firstName} ${lastName}`);
  const identifyEvent = new AnalyticsEvent(AnalyticsEventName.IDENTIFY, {
    userEmail: email,
    userFirstName: firstName || "",
    userLastName: lastName || "",
  });
  trackEvent(identifyEvent);
};

export const trackEvent = (event: IAnalyticsEvent) => {
  pushToDataLayer(convertToDataLayer(event));
};

export { AnalyticsEvent };
