import { Api } from "src/utils/api";
import { CANVAS_API_BASE_URL } from "./canvas.constant";

type PerformanceType = "All" | "Good" | "Mid" | "Poor" | "No Sales";

export interface IPerformanceTableData {
  performance: string;
  sales: number;
  spend: number;
  acos: number;
  cpc: number;
  key: number | string;
  campaign_name?: string;
}

export interface IPerformanceTableDataCampaign extends IPerformanceTableData {
  campaign_name: string;
}

export interface ISaturationCurveData {
  x: number;
  y: number;
  color: string;
}

export interface BaseRequestParams {
  date_start: string;
  date_end: string;
  saturation?: number;
  asins?: string[];
  saturation_keys?: string[];
  details?: boolean;
}

export class CanvasApi extends Api {
  constructor() {
    super(CANVAS_API_BASE_URL);
  }

  private async makeRequest<T>(
    endpoint: string,
    params: BaseRequestParams & Record<string, any>,
    isGetRequest = false,
  ): Promise<T> {
    const { saturation, asins, saturation_keys, details, ...otherParams } =
      params;

    const requestParams = this.createParams(
      {
        date_start: params.date_start,
        date_end: params.date_end,
        saturation,
        asins,
        saturation_keys,
        details,
      },
      otherParams,
    );

    if (isGetRequest) {
      return this.get<T>(endpoint, requestParams);
    }

    return this.post<T>(endpoint, requestParams);
  }

  private createParams(
    baseParams: BaseRequestParams,
    additionalParams: Record<string, any> = {},
  ): Record<string, any> {
    const {
      date_start,
      date_end,
      saturation,
      asins,
      saturation_keys,
      details,
    } = baseParams;

    return {
      date_start,
      date_end,
      saturation: saturation ?? undefined,
      asins: asins ?? [],
      saturation_keys: saturation_keys ?? [],
      details: details ?? false,
      ...additionalParams,
    };
  }

  public getSalesPerformance = async (
    date_start: string,
    date_end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("", {
      date_start,
      date_end,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceCampaign = async (
    date_start: string,
    date_end: string,
    performance: PerformanceType,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/campaign", {
      date_start,
      date_end,
      performance,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceTarget = async (
    date_start: string,
    date_end: string,
    performance: PerformanceType,
    campaign_name: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/target", {
      date_start,
      date_end,
      performance,
      campaign_name,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceAdType = async (
    date_start: string,
    date_end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/ad-type", {
      date_start,
      date_end,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceMatchType = async (
    date_start: string,
    date_end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/match-type", {
      date_start,
      date_end,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSaturationCurve = async (
    date_start: string,
    date_end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest(
      "/saturation-curve",
      {
        date_start,
        date_end,
        saturation,
        asins,
        saturation_keys,
        details,
      },
      true,
    );
  };

  public getSalesPerformanceAdTypeCampaign = async (
    date_start: string,
    date_end: string,
    ad_type: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/ad-type/campaign", {
      date_start,
      date_end,
      ad_type,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceAdTypeSearchTerms = async (
    date_start: string,
    date_end: string,
    ad_type: string,
    campaign_name: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/ad-type/search-terms", {
      date_start,
      date_end,
      ad_type,
      campaign_name,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceMatchTypeCampaign = async (
    date_start: string,
    date_end: string,
    match_type: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/match-type/campaign", {
      date_start,
      date_end,
      match_type,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceMatchTypeTarget = async (
    date_start: string,
    date_end: string,
    match_type: string,
    campaign_name: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/match-type/target", {
      date_start,
      date_end,
      campaign_name,
      match_type,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceTargets = async (
    date_start: string,
    date_end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/targets", {
      date_start,
      date_end,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceTargetsCampaigns = async (
    date_start: string,
    date_end: string,
    target: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/targets/campaigns", {
      date_start,
      date_end,
      target,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceFunnel = async (
    date_start: string,
    date_end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/funnels", {
      date_start,
      date_end,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceFunnelCampaign = async (
    date_start: string,
    date_end: string,
    funnel: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/funnels/campaigns", {
      date_start,
      date_end,
      funnel,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceFunnelSearchTerms = async (
    date_start: string,
    date_end: string,
    funnel: string,
    campaign_name: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/funnels/search-terms", {
      date_start,
      date_end,
      funnel,
      campaign_name,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceCampaigns = async (
    date_start: string,
    date_end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableData[]> => {
    return this.makeRequest("/campaigns", {
      date_start,
      date_end,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };

  public getSalesPerformanceCampaignsSearchTerms = async (
    date_start: string,
    date_end: string,
    campaign_name: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
    details: boolean = false,
  ): Promise<IPerformanceTableDataCampaign[]> => {
    return await this.makeRequest("/campaigns/search-terms", {
      date_start,
      date_end,
      campaign_name,
      saturation,
      asins,
      saturation_keys,
      details,
    });
  };
}
