import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { ExpandableTable } from "@components/ExpandebleTable";
import { getData, generateColumns } from "./utils";
import { TableTab } from "./Tabs/models";
import { Tabs } from "./Tabs";

interface OrganicTableProps {
  isWhatIf?: boolean;
  autoHeight?: boolean;
}

export const OrganicTable: React.FC<OrganicTableProps> = ({ isWhatIf }) => {
  const [selections, setSelections] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<TableTab>(
    "Organic Keyword Performance",
  );
  const tableRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (tableRef.current) {
      setHeight(tableRef.current?.offsetHeight - 200);
    }
  }, [tableRef.current?.offsetHeight]);

  const rowSelections = {
    selectedRowKeys: selections,
    onChange: (selectedRowKeys: any) => {
      setSelections(selectedRowKeys);
    },
  };

  return (
    <div className={styles.container} ref={tableRef}>
      <div className={styles.header}>
        <h3>Organic Keyword Performance</h3>
      </div>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <ExpandableTable
        columns={generateColumns(activeTab, isWhatIf)}
        data={getData(activeTab)}
        rowSelection={rowSelections}
        scroll={{ x: "max-content", y: `${height}px` }}
      />
    </div>
  );
};
