import { create } from "zustand";

interface AdvancedViewState {
  isAdvancedView: boolean;
  setIsAdvancedView: (value: boolean) => void;
}

export const useAdvancedViewStore = create<AdvancedViewState>((set) => ({
  isAdvancedView: false,
  setIsAdvancedView: (value) => set({ isAdvancedView: value }),
}));
