import styles from "../Auth.module.scss";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import { IGetRedirectUrlResponse } from "src/@types/auth.types";
import { AuthProvider } from "src/constants/auth.constants";
import { AuthNavigation } from "@pages/Auth/AuthNavigation.tsx";
import { LS } from "src/constants/localStorage.constants.ts";
import { Button } from "@components/ui-kit/Button/Button.tsx";
import { useTranslation } from "react-i18next";
import AuthInput from "@components/AuthInput";
import AuthButton from "@components/AuthButton";
import Google from "@assets/icons/google";
import { useAuthStore } from "src/store/auth.state";
import LoginService from "@services/login.service";
import { AuthServices } from "@services/auth.services";
import { ProfileService } from "@services/ProfileService";
import { identifyUser } from "src/utils/analytics";

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [generalError, setGeneralError] = useState("");
  const setAuthenticated = useAuthStore((state) => state.setAuthenticated);

  const onLogin = useCallback((provider: AuthProvider) => {
    return () => {
      setLoading(true);
      AuthServices.getRedirectUrl(provider)
        .then(({ url }: IGetRedirectUrlResponse) => {
          localStorage.setItem(LS.AuthProvider, provider);
          window.location.replace(url);
        })
        .catch(() => {
          setLoading(false);
        });
    };
  }, []);

  const validateForm = () => {
    const newErrors = { email: "", password: "" };
    let valid = true;

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      valid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      valid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleLogin = useCallback(() => {
    setGeneralError("");

    if (!validateForm()) return;

    setLoading(true);
    setErrors({ email: "", password: "" });

    LoginService.login(formData)
      .then((data) => {
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        setAuthenticated(true);
        ProfileService.get()
          .then((profile) => {
            if (profile && profile.email) {
              identifyUser(
                profile.email,
                profile.first_name,
                profile.last_name,
              );
            }
            setLoading(false);
            navigate(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/generator`);
          })
          .catch((profileError) => {
            console.error("Error fetching profile: ", profileError);
            setLoading(false);
          });
      })
      .catch((error) => {
        const err = error.response ? error.response.data : error;

        if (err.detail) {
          setGeneralError("Incorrect password or login");
        } else {
          setGeneralError("Something went wrong");
        }
        setLoading(false);
      });
  }, [formData, navigate, setAuthenticated]);

  const inputs = [
    {
      title: "e-mail",
      type: "email",
      value: formData.email,
      error: errors.email,
      onChange: (e) => setFormData({ ...formData, email: e.target.value }),
    },
    {
      title: "password",
      type: "password",
      value: formData.password,
      error: errors.password,
      onChange: (e) => setFormData({ ...formData, password: e.target.value }),
      password: true,
    },
  ];

  return (
    <div className={styles.authContainer}>
      <AuthNavigation />
      <div className={styles.inputList}>
        {inputs.map((input, index) => (
          <div key={index} className={styles.inputItem}>
            <AuthInput
              title={input.title}
              type={input.type}
              value={input.value}
              onChange={input.onChange}
              password={input.password || false}
              error={input.error || (generalError ? "error" : "")}
            />
            {input.error && (
              <div className={styles.errorMessage}>{input.error}</div>
            )}
          </div>
        ))}
      </div>
      {generalError && (
        <div className={styles.errorMessage}>{generalError}</div>
      )}
      <div className={styles.forgotPassword}>
        <Link to={`${ROUTES.AUTH}/${ROUTES.AUTH_FORGOT}`}>
          I forgot my password
        </Link>
      </div>
      <AuthButton text="Log In" onClick={handleLogin} />

      <div className={styles.authBtnsBox}>
        <Button
          variant="green"
          onClick={onLogin(AuthProvider.Google)}
          loading={loading}
        >
          <p className={styles.authBtn}>
            {t("action.login_with_google")} <Google />
          </p>
        </Button>
      </div>
      <div className={styles.authPagePrivacy}>
        <p className={styles.acknowledgementText}>
          By signing up, I acknowledge that I have read and agree to the
          <a
            href="https://www.nyle.ai/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
          <span className={styles.spanAnd}>and </span>
          <a
            href="https://www.nyle.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>

        <p className={styles.account}>
          Don't you have an account yet? Then{" "}
          <Link to={`${ROUTES.AUTH}/${ROUTES.AUTH_SIGNUP}`}>register</Link>
        </p>
      </div>
    </div>
  );
};
