import React, { useState } from "react";
import { Button, Card, Switch } from "antd";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Customized,
} from "recharts";
import styles from "./styles.module.scss";
import InfoIcon from "@assets/icons/i.svg";

import {
  getTransformedData,
  legendColorMapping,
  legendOrder,
  nameMapping,
  legendColorMappingNoForecast,
  legendOrderNoForecast,
  nameMappingNoForecast,
} from "./utils";

import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { CustomBar } from "@components/BarChart";
import { Wave } from "@components/Wave";
import { useIsMobile } from "src/utils/isMobile";

interface FinanceFlowProps {
  showForecast?: boolean;
  title?: boolean;
  dataPoints?: Record<string, boolean>;
}

const FinanceFlowChart: React.FC<FinanceFlowProps> = ({
  showForecast,
  title,
  dataPoints,
}) => {
  const isControlled = showForecast !== undefined;
  const [showForecastIs, setShowForecastIs] = useState<boolean>(
    showForecast ?? false,
  );
  const effectiveShowForecast = isControlled ? showForecast! : showForecastIs;
  const chartData = getTransformedData(effectiveShowForecast);

  const currentNameMapping = isControlled ? nameMapping : nameMappingNoForecast;
  const currentLegendOrder = isControlled ? legendOrder : legendOrderNoForecast;
  const currentLegendColorMapping = isControlled
    ? legendColorMapping
    : legendColorMappingNoForecast;

  const filteredLegendOrder = dataPoints
    ? currentLegendOrder.filter((key) => dataPoints[key])
    : currentLegendOrder;

  const isMobile = useIsMobile(1200);

  const chartMargins = isMobile
    ? { top: 20, right: 30, left: -20, bottom: 5 }
    : { top: 20, right: 30, left: 50, bottom: 5 };

  const [activeLabel, setActiveLabel] = useState<string | null>(null);

  return (
    <Card className={styles.card}>
      <div className={styles.headerBetween}>
        <h1 className={styles.title}>{title ? "Cash Flow" : "Finance Flow"}</h1>
      </div>

      <div className={styles.rowLegend}>
        <div className={styles.legendContainer}>
          {filteredLegendOrder.map((key) => {
            const hasKeyInData = chartData.some((item) => key in item);
            if (!hasKeyInData) return null;
            return (
              <div className={styles.legendItem} key={key}>
                <span
                  className={styles.colorDot}
                  style={{ backgroundColor: currentLegendColorMapping[key] }}
                />
                <span className={styles.legendText}>
                  {currentNameMapping[key]}
                </span>
              </div>
            );
          })}

          {!effectiveShowForecast && !isControlled && (
            <div className={styles.legendItem}>
              <span className={styles.colorDot} />
              <span className={styles.legendText}>Cumulative SF</span>
            </div>
          )}
        </div>

        {!isControlled && (
          <div className={styles.switchRow}>
            <div className={styles.switchContainer}>
              <Switch
                checked={showForecastIs}
                onChange={(checked) => setShowForecastIs(checked)}
              />
              <span className={styles.switchLabel}>Show Forecast</span>
            </div>

            <div className={styles.switchContainer}>
              <Switch />
              <span className={styles.switchLabel}>Show Past Data</span>
            </div>
            <Button
              type="link"
              className={styles.infoButton}
              icon={
                <img
                  src={InfoIcon}
                  alt="Info"
                  className={styles.infoIcon}
                  width={14}
                  height={14}
                />
              }
            />
          </div>
        )}
      </div>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={390}>
          <ComposedChart
            data={chartData}
            margin={chartMargins}
            stackOffset="sign"
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setActiveLabel(state.activeLabel as string);
              } else {
                setActiveLabel(null);
              }
            }}
            onMouseLeave={() => setActiveLabel(null)}
          >
            <CartesianGrid stroke="#1F1F20" vertical={false} />
            <XAxis
              dataKey="date"
              tick={{ fill: "white" }}
              axisLine={{ stroke: "#1F1F20" }}
            />
            <YAxis
              tick={{ fill: "white" }}
              axisLine={{ stroke: "#1F1F20" }}
              domain={[-10000, 10000]}
              tickFormatter={(value) => `$${value / 1000}K`}
            />
            <Tooltip
              content={({ active, payload, label }) => (
                <CustomTooltip
                  active={active}
                  payload={payload}
                  colorMetric={currentLegendColorMapping}
                  label={label}
                  disableMapping={true}
                />
              )}
            />

            {filteredLegendOrder.map((key) => {
              const hasKeyInData = chartData.some((item) => key in item);
              if (!hasKeyInData) return null;
              return (
                <Bar
                  key={key}
                  dataKey={key}
                  stackId="a"
                  fill={currentLegendColorMapping[key]}
                  name={currentNameMapping[key]}
                  shape={(barProps) => (
                    <CustomBar
                      {...barProps}
                      activeLabel={activeLabel}
                      showForecast={effectiveShowForecast}
                    />
                  )}
                />
              );
            })}

            <Customized
              component={({ width, height }) => {
                const leftMargin = chartMargins.left;
                const rightMargin = chartMargins.right;
                const plotWidth = width - leftMargin - rightMargin;
                return (
                  <svg width={width} height={height}>
                    <Wave
                      width={plotWidth}
                      height={height - chartMargins.top - chartMargins.bottom}
                      numberOfBars={7}
                      shiftPx={110}
                      cumulativeStart={-5000}
                      yDomain={[-10000, 10000]}
                    />
                  </svg>
                );
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default FinanceFlowChart;
