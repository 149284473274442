import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import CarretLeft from "@assets/icons/CarretLeft";
import classNames from "classnames";
import CampaignSliderLeft from "@assets/icons/campaignSliderLeft";
import CampaignSliderRight from "@assets/icons/campaignSliderRight";
import { RecomendationModal } from "@components/RecomendationModal";
import { Carousel } from "antd";
import type { CarouselRef } from "antd/es/carousel";

export const content = [
  {
    id: 1,
    title: "Optimization recommendations",
    description:
      "In order to meet selected ACOS, please optimize Ad Spend, Bids and Reduced Ad Spend",
  },
  {
    id: 2,
    title: "Missing keywords",
    description:
      "You have 26 target recommendations for Sponsored Products campaigns with low clickthrough rate and low conversion rate, compared to similar campaigns. Applying these keyword recommendations can increase sales.",
  },
];

export const OptimizationSlider: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isRecOpen, setIsRecOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselRef = useRef<CarouselRef>(null);

  const goToPrev = () => {
    carouselRef.current?.prev();
  };

  const goToNext = () => {
    carouselRef.current?.next();
  };

  const handleSlideChange = (currentSlide: number) => {
    setActiveSlide(currentSlide);
  };

  const openRecommendationDetails = () => {
    setIsRecOpen(true);
  };

  return (
    <>
      <RecomendationModal
        visible={isRecOpen}
        onCancel={() => setIsRecOpen(false)}
      />
      <div
        className={classNames(styles.container, { [styles.closed]: !isOpen })}
      >
        <div className={styles.wrapper}>
          <button
            className={styles.openButton}
            onClick={() => setIsOpen(!isOpen)}
          >
            <CarretLeft />
          </button>

          <div className={styles.pagination}>
            <div className={styles.nav}>
              <button
                className={styles.navButton}
                onClick={goToPrev}
                aria-label="Previous slide"
              >
                <CampaignSliderLeft />
              </button>
              <button
                className={styles.navButton}
                onClick={goToNext}
                aria-label="Next slide"
              >
                <CampaignSliderRight />
              </button>
            </div>
            <div className={styles.pag}>
              {content.map((_, index) => (
                <div
                  key={index}
                  className={classNames(styles.dot, {
                    [styles.active]: index === activeSlide,
                  })}
                  onClick={() => carouselRef.current?.goTo(index)}
                />
              ))}
            </div>
          </div>

          <div className={styles.contentWrapper}>
            <Carousel
              ref={carouselRef}
              dots={false}
              afterChange={handleSlideChange}
              effect="fade"
              className={styles.carousel}
            >
              {content.map((item) => (
                <div key={item.id}>
                  <div className={styles.slide}>
                    <h3 className={styles.title}>{item.title}</h3>
                    <p className={styles.description}>{item.description}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className={styles.slide__action}>
            <button
              className={styles.details}
              onClick={openRecommendationDetails}
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
