import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { ExpandableTable } from "@components/ExpandebleTable";
import { ProductsService } from "@services/amazon/products/products.service";
import { Brand } from "src/store/brand";
import { columns } from "../TotalAsinBlock/mock";

interface BrandTableProps {
  isWhatIf?: boolean;
}

export const BrandTable: React.FC<BrandTableProps> = ({ isWhatIf }) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [brandPage, setBrandPage] = useState(1);
  const [selections, setSelections] = useState<any[]>([]);

  const rowSelections = {
    selectedRowKeys: selections,
    onChange: (selectedRowKeys: any) => {
      setSelections(selectedRowKeys);
    },
  };

  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        const productsService = new ProductsService();
        const productBrandData: Brand[] =
          await productsService.getOwnProductsBrand({ limit: 32 * brandPage });
        const newTableData = productBrandData.map((brand, brandIndex) => {
          return {
            key: `brand-${brandIndex}`,
            category: brand.name || "NoBran",
            searchVolume: "$27",
            organicRank: "24.74%",
            organicImpressions: "$374,263",
            TotalWhatIf: "$2374,263",
            delta: "100%",
            impressionsShare: "25.7%",
            organicClicks: "$125,231",
            contributionProfitWhatIf: "$800",
            contributionMarginWhatIf: "$2020",
            organicUnitsSold: "47%",
            roi: "155%",
            children: brand.asins.map((asin, asinIndex) => ({
              key: `asin-${brandIndex}-${asinIndex}`,
              category: asin,
              searchVolume: "$29",
              organicRank: "22.74%",
              organicImpressions: "$313,743",
              TotalWhatIf: "$5374,263",
              delta: "100%",
              contributionProfitWhatIf: "$500",
              contributionMarginWhatIf: "$1020",
              impressionsShare: "11.2%",
              organicClicks: "$933,392",
              organicUnitsSold: "47%",
              roi: "155%",
            })),
          };
        });
        setTableData(newTableData);
      } catch (error) {
        console.error("Error fetching brand data:", error);
      }
    };

    fetchBrandData();
  }, [brandPage]);

  const processedColumns = useMemo(() => {
    return columns.filter((col) => {
      if (
        !isWhatIf &&
        (col.dataIndex === "contributionMarginWhatIf" ||
          col.dataIndex === "TotalWhatIf" ||
          col.dataIndex === "contributionProfitWhatIf")
      ) {
        return false;
      }

      return true;
    });
  }, [isWhatIf, columns]);

  return (
    <div className={styles.expandedTable}>
      <ExpandableTable
        data={tableData}
        withCustomScroll
        rowSelection={rowSelections}
        columns={processedColumns}
        headerClassName={true}
      />
    </div>
  );
};
