import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Option, Select } from "@components/Select";
import { useEffect, useState, useCallback } from "react";
import { Brand, ProductWithImageLink } from "@services/amazon/products/types";
import { Input } from "@components/Input";
import { ProductsService } from "@services/amazon/products/products.service";
import Search from "@assets/icons/search";

interface BrandAsinSelectProps {
  className?: string;
}

export const BrandAsinSelect: React.FC<BrandAsinSelectProps> = ({
  className,
}) => {
  const productsService = new ProductsService();
  const [asinOptions, setAsinOptions] = useState<Option[]>([]);
  const [brandOptions, setBrandOptions] = useState<Option[]>([]);
  const [asinPage, setAsinPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [searchAsin, setSearchAsin] = useState("");
  const [originalBrandOptions, setOriginalBrandOptions] = useState<Option[]>(
    [],
  );
  const [originalAsinOptions, setOriginalAsinOptions] = useState<Option[]>([]);

  const {
    isAsinSelect,
    asinSelected,
    brandSelected,
    setAsinBrandSelect,
    setBrandSelected,
    setAsinSelected,
    setBrandName,
    setAsinName,
  } = useDashboardStore();

  const fetchOptions = useCallback(async () => {
    try {
      const [productAsinData, productBrandData] = await Promise.all([
        productsService.getOwnProducts({
          limit: 32 * asinPage,
        }),
        productsService.getOwnProductsBrand({
          limit: 32 * brandPage,
        }),
      ]);

      const names = productBrandData.map((item) => item.name);
      const asinNames = productAsinData.map((item) => item.asin);
      setBrandName(names);
      setAsinName(asinNames);

      const newAsinOptions = productAsinData.map(
        (product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }),
      );

      const newBrandOptions = productBrandData.map((product: Brand) => ({
        id: product.name,
        text: product.asins.join(", "),
      }));

      // Save the original options order
      setOriginalAsinOptions(newAsinOptions);
      setOriginalBrandOptions(newBrandOptions);

      // Set the current displayed options
      setAsinOptions(newAsinOptions);
      setBrandOptions(newBrandOptions);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, [asinPage, brandPage]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  // When switching tabs, restore the original ordering
  useEffect(() => {
    if (isAsinSelect) {
      setAsinOptions(originalAsinOptions);
    } else {
      setBrandOptions(originalBrandOptions);
    }
  }, [isAsinSelect]);

  useEffect(() => {
    const fetchSearchData = async () => {
      if (!searchAsin) {
        // Reset to original ASIN options if search is empty
        setAsinOptions(originalAsinOptions);
        return;
      }

      try {
        const data = await productsService.searchOwnProducts({
          limit: 32,
          asin: searchAsin,
        });
        if (!data) return;
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchSearchData();
  }, [searchAsin]);

  const handleChange = (value: string[]) => {
    if (isAsinSelect) {
      setAsinSelected(value);
    } else {
      setBrandSelected(value);
    }
  };

  const handleReset = () => {
    if (isAsinSelect) {
      setAsinSelected([]);
    } else {
      setBrandSelected([]);
    }
  };

  const selectOptions = {
    value: isAsinSelect ? asinSelected : brandSelected,
    options: isAsinSelect ? asinOptions : brandOptions,
    onChange: handleChange,
    placeholder: isAsinSelect ? "Select ASIN" : "Select Brand",
    className: styles.select,
    isMultiSelect: true,
    isOptionsOutside: true,
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.switch__container}>
        <button
          className={classNames(styles.switch, {
            [styles.active]: !isAsinSelect,
          })}
          onClick={() => setAsinBrandSelect(false)}
        >
          Brand
        </button>
        <button
          className={classNames(styles.switch, {
            [styles.active]: isAsinSelect,
          })}
          onClick={() => setAsinBrandSelect(true)}
        >
          ASIN
        </button>
      </div>
      <Select {...selectOptions}>
        <div className={styles.search}>
          {isAsinSelect && (
            <Input
              placeholder="Search"
              value={searchAsin}
              icon={<Search />}
              onChange={(value) => setSearchAsin(value)}
            />
          )}
          <button className={styles.reset} onClick={handleReset}>
            Reset
          </button>
        </div>
      </Select>
    </div>
  );
};
