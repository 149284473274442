import React, { useMemo } from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { WidgetClass } from "@services/widget.service";
import { ChartDataPoint } from "@services/sales-performance/sales-performance.types";
import { useIsMobile } from "src/utils/isMobile";

interface ChartDimensions {
  margin: { top: number; right: number; left: number; bottom: number };
  tickPadding: { left: number; right: number };
  height: number;
  xAxisDy: number;
}

const getResponsiveDimensions = (isMobile: boolean): ChartDimensions => ({
  margin: isMobile
    ? { top: 20, right: -25, left: -25, bottom: 20 }
    : { top: 20, right: 30, left: 20, bottom: 14 },
  tickPadding: {
    left: isMobile ? 10 : 0,
    right: isMobile ? -10 : 0,
  },
  height: isMobile ? 250 : 350,
  xAxisDy: isMobile ? 5 : 10,
});

interface ColumnChartProps {
  dataSet: ChartDataPoint[];
  activeLabel: string | null;
  setActiveLabel: React.Dispatch<React.SetStateAction<string | null>>;
  metrics: string[];
  metricsColors: { [key: string]: string };
  isForecasted: boolean;
}

export const BarChart: React.FC<ColumnChartProps> = ({
  dataSet,
  activeLabel,
  setActiveLabel,
  metrics,
  metricsColors,
  isForecasted,
}) => {
  const widgetClass = new WidgetClass();
  const calculatedBarSize = Math.max(10, 60 / metrics.length);
  const isMobile = useIsMobile();

  const dimensions = useMemo(
    () => getResponsiveDimensions(isMobile),
    [isMobile],
  );

  return (
    <ResponsiveContainer width="100%" height={dimensions.height}>
      <RechartsBarChart
        data={dataSet}
        margin={dimensions.margin}
        barCategoryGap="20%"
        onMouseMove={(state) => {
          if (state.isTooltipActive) {
            setActiveLabel(state.activeLabel as string);
          } else {
            setActiveLabel(null);
          }
        }}
        onMouseLeave={() => setActiveLabel(null)}
      >
        <CartesianGrid
          stroke="#1F1F20"
          vertical={false}
          horizontalCoordinatesGenerator={(props) => {
            const { height } = props;
            return Array.from(
              { length: isMobile ? 5 : 8 },
              (_, i) => height * (i / (isMobile ? 4 : 8)),
            );
          }}
        />
        <XAxis
          dataKey="date"
          tick={{
            fill: "#FFFFFF",
            dy: dimensions.xAxisDy,
            fontSize: isMobile ? 10 : 12,
          }}
          tickLine={false}
          axisLine={false}
        />
        <Tooltip
          content={({ active, payload, label }) => (
            <CustomTooltip
              active={active}
              payload={payload}
              label={label}
              colorMetric={metricsColors}
            />
          )}
          cursor={false}
        />
        {metrics.map((metric, index) => {
          const yAxisId = metric;
          const orientation = index % 2 === 0 ? "left" : "right";
          const tickColor = metricsColors[metric];
          const tickFormatter = (value: number) =>
            widgetClass.formatValue(metric, value);

          return (
            <React.Fragment key={metric}>
              <YAxis
                key={yAxisId}
                yAxisId={yAxisId}
                orientation={orientation}
                stroke={tickColor}
                tickFormatter={tickFormatter}
                axisLine={false}
                tickLine={false}
                domain={["auto", "auto"]}
                tickCount={isMobile ? 5 : 11}
                tick={{
                  fill: tickColor,
                  dx: dimensions.tickPadding[orientation],
                  fontSize: isMobile ? 10 : 12,
                }}
                width={isMobile ? 50 : 60}
              />
              <Bar
                key={metric}
                dataKey={metric}
                yAxisId={metric}
                fill={metricsColors[metric]}
                fillOpacity={isForecasted ? 0.5 : 1}
                strokeDasharray={isForecasted ? "5 5" : "0"}
                stroke={isForecasted ? metricsColors[metric] : "none"}
                barSize={calculatedBarSize}
              />
            </React.Fragment>
          );
        })}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};
