import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useCanvaStore } from "src/store/canva/canva.state";

interface ModalBoxProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  className?: string;
}

const ModalBox: React.FC<ModalBoxProps> = ({
  isOpen,
  onClose,
  title,
  children,
  hideCloseButton = false,
  className,
}) => {
  const { disableZoom, enableZoom } = useCanvaStore();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";

      const preventDefault = (e: Event) => {
        e.preventDefault();
      };

      document.body.addEventListener("touchmove", preventDefault, {
        passive: false,
      });
      document.body.addEventListener("wheel", preventDefault, {
        passive: false,
      });

      return () => {
        document.body.style.overflow = "";
        document.body.removeEventListener("touchmove", preventDefault);
        document.body.removeEventListener("wheel", preventDefault);
      };
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      disableZoom();
    } else {
      enableZoom();
    }
  }, [isOpen, disableZoom, enableZoom]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      onClose();
      enableZoom();
    }
  };

  const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      onClose();
      enableZoom();
    }
    e.stopPropagation();
  };

  if (!isOpen) return null;

  const modalContent = (
    <div
      className={styles.modalOverlay}
      onClick={handleOverlayClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div
        className={classNames(styles.modalContent, className)}
        onClick={handleModalContentClick}
      >
        {!hideCloseButton && (
          <button
            className={styles.modalCloseButton}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
              enableZoom();
            }}
          >
            <span className={styles.closeIcon}></span>
          </button>
        )}
        <h2 className={styles.modalTitle}>{title}</h2>
        {children}
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default ModalBox;
