import { v4 as uuid } from "uuid";
export const sbColumns = [
  {
    title: "Keyword Phrase",
    dataIndex: "keyword_phrase",
    key: "keyword_phrase",
    width: 340,
  },
  {
    title: "Bid Before",
    dataIndex: "bid_before",
    key: "bid_before",
    width: 140,
  },
  {
    title: "Bid After",
    dataIndex: "bid_after",
    key: "bid_after",
    width: 140,
  },
  {
    title: "Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  {
    title: "Decision",
    dataIndex: "decision",
    key: "decision",
    width: 140,
  },
];

export const targetingColumns = [
  {
    title: "Targeting",
    dataIndex: "keyword_phrase",
    key: "keyword_phrase",
    width: 340,
  },
  {
    title: "Bid Before",
    dataIndex: "bid_before",
    key: "bid_before",
    width: 140,
  },
  {
    title: "Bid After",
    dataIndex: "bid_after",
    key: "bid_after",
    width: 140,
  },
  {
    title: "Spend",
    dataIndex: "ad_spend",
    key: "ad_spend",
    width: 140,
  },
  {
    title: "Decision",
    dataIndex: "decision",
    key: "decision",
    width: 140,
  },
];

export const data = [
  {
    key: 1,
    keyword_phrase: "crest whitening strips",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$116.61",
    decision: "Increase Bid",
  },
  {
    key: 2,
    keyword_phrase: "crest 3d whitestrips",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$115.61",
    decision: "Increase Bid",
  },
  {
    key: 3,
    keyword_phrase: "teeth whitening pen",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$114.61",
    decision: "Increase Bid",
  },
  {
    key: 4,
    keyword_phrase: "blanqueador de dientes",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$113.61",
    decision: "Increase Bid",
  },
  {
    key: 5,
    keyword_phrase: "teeth whitening gel",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$112.61",
    decision: "Increase Bid",
  },
  {
    key: 6,
    keyword_phrase: "crest white strips",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$111.61",
    decision: "Increase Bid",
  },
  {
    key: 7,
    keyword_phrase: "purple toothpaste for teeth whitening",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$110.61",
    decision: "Increase Bid",
  },
  {
    key: 8,
    keyword_phrase: "white teeth",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$109.61",
    decision: "Increase Bid",
  },
];

export const sb = [
  {
    key: uuid(),
    keyword_phrase: "Campaign Name",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$460.33",
    decision: "Increase Bid",
    children: [
      {
        key: uuid(),
        keyword_phrase: "crest whitening strips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$116.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "crest 3d whitestrips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$115.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "teeth whitening pen",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$114.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "blanqueador de dientes",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$113.61",
        decision: "Increase Bid",
      },
    ],
  },
  {
    key: uuid(),
    keyword_phrase: "Campaign Name",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$460.33",
    decision: "Increase Bid",
    children: [
      {
        key: uuid(),
        keyword_phrase: "crest whitening strips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$116.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "crest 3d whitestrips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$115.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "teeth whitening pen",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$114.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "blanqueador de dientes",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$113.61",
        decision: "Increase Bid",
      },
    ],
  },
  {
    key: uuid(),
    keyword_phrase: "Campaign Name",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$460.33",
    decision: "Increase Bid",
    children: [
      {
        key: uuid(),
        keyword_phrase: "crest whitening strips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$116.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "crest 3d whitestrips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$115.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "teeth whitening pen",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$114.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "blanqueador de dientes",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$113.61",
        decision: "Increase Bid",
      },
    ],
  },
];

export const sd = [
  {
    key: uuid(),
    keyword_phrase: "Campaign Name",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$460.33",
    decision: "Increase Bid",
    children: [
      {
        key: uuid(),
        keyword_phrase: 'audience="IM - Oral Care Products"',
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$116.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "similar-product",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$115.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "teeth whitening pen",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$114.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: " views-(similar-product lookback=90)",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$113.61",
        decision: "Increase Bid",
      },
    ],
  },
  {
    key: uuid(),
    keyword_phrase: "Campaign Name",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$460.33",
    decision: "Increase Bid",
    children: [
      {
        key: uuid(),
        keyword_phrase: "crest whitening strips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$116.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "crest 3d whitestrips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$115.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "teeth whitening pen",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$114.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "blanqueador de dientes",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$113.61",
        decision: "Increase Bid",
      },
    ],
  },
  {
    key: uuid(),
    keyword_phrase: "Campaign Name",
    bid_before: "$2.3",
    bid_after: "$2.64",
    ad_spend: "$460.33",
    decision: "Increase Bid",
    children: [
      {
        key: uuid(),
        keyword_phrase: "crest whitening strips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$116.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "crest 3d whitestrips",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$115.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "teeth whitening pen",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$114.61",
        decision: "Increase Bid",
      },
      {
        key: uuid(),
        keyword_phrase: "blanqueador de dientes",
        bid_before: "$2.3",
        bid_after: "$2.64",
        ad_spend: "$113.61",
        decision: "Increase Bid",
      },
    ],
  },
];
