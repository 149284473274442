import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Switch } from "antd";
import Setting from "@assets/icons/setting";
import { AdvancedView } from "./AdvancedView";
import { DefaultView } from "./DefaultView";
import {
  MarketWidgets,
  useDashboardStore,
} from "@pages/Dashboard/store/dashboard.state";
import { WidgetMetricKey } from "@services/math/math.service";
import { SettingsModal } from "./SettingsBigModal";
import { updateElementSize } from "@pages/CanvasEditor/Canva";
import { GridStack } from "gridstack";
import { ROUTES } from "src/router/routes";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { BrandAsinSelect } from "@components/brand-asin-select";
import { useMarketStore } from "src/store/market.state";
import { useSectionStore } from "src/store/croModule.state";

const MAXIMUM_WIDGETS = 10;

interface ExecutiveSummaryProps {
  hideSwitch?: boolean;
  titles?: string[];
  id?: string;
  showWhatIf?: boolean;
  gridRef?: React.RefObject<GridStack>;
  alwaysDefault?: boolean;
  brand?: boolean;
  hideAllConfig?: boolean;
  cfoTitles?: string[];
  showCOO?: boolean;
}

export const ExecutiveSummary: React.FC<ExecutiveSummaryProps> = ({
  hideSwitch = false,
  titles,
  id,
  showWhatIf,
  gridRef,
  alwaysDefault,
  brand,
  hideAllConfig,
  cfoTitles,
  showCOO,
}) => {
  const [isAdvancedView, setIsAdvancedView] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showHistorical, setShowHistorical] = useState(false);
  const location = useLocation();
  const isCRO = location.pathname === `/${ROUTES.CRO}`;
  const { isWhatIf, setIsWhatIf } = useMarketStore();
  const { changeWhatIf: setCroWhatIf } = useSectionStore();

  const handleToggle = () => {
    setIsWhatIf(!isWhatIf);
    setCroWhatIf(!isWhatIf);
  };

  const {
    selectedWidgets,
    selectedWidgetsMarket,
    selectedWidgetsCOOSupply,
    selectedWidgetsCOO,
    setSelectedWidgets,
    setSelectedWidgetsMarket,
  } = useDashboardStore();

  const initialWidgets: WidgetMetricKey[] = titles
    ? selectedWidgetsMarket
    : selectedWidgets;

  const [choosedWidgets, setChoosedWidgets] =
    useState<WidgetMetricKey[]>(initialWidgets);

  const onApply = () => {
    if (titles) {
      setSelectedWidgetsMarket(choosedWidgets);
    } else {
      setSelectedWidgets(choosedWidgets);
    }
    setIsOpenModal(false);
  };
  const getChoosenWidgets = () => {
    switch (true) {
      case !!cfoTitles:
        return selectedWidgetsCOOSupply;
      case !!titles:
        return MarketWidgets;
      case !!showCOO:
        return selectedWidgetsCOO;
      default:
        return selectedWidgets;
    }
  };
  const allowedGroups = titles ? ["Market"] : undefined;

  const onChangeView = () => {
    if (isAdvancedView) {
      updateElementSize({
        id: id || "",
        size: { height: 42 },
        gridRef: gridRef,
      });
      setIsAdvancedView(false);
    } else {
      updateElementSize({
        id: id || "",
        size: { height: 211 },
        gridRef: gridRef,
      });
      setIsAdvancedView(true);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(brand ? styles.header : styles.settingContainer, {
          [styles.cro]: isCRO,
        })}
      >
        {!hideAllConfig && <h3>Executive Summary</h3>}

        {!hideSwitch && (
          <div className={styles.switch__container}>
            <Switch onChange={onChangeView} />
            <span className={styles.advansedSpan}>Advanced View</span>
          </div>
        )}
        <div
          className={classNames({
            [styles.brandSettings]: brand,
            [styles.settingsBlock]: !brand,
          })}
        >
          {brand && (
            <>
              <BrandAsinSelect className={styles.select} />
              <button className={styles.whatIf} onClick={handleToggle}>
                {isWhatIf ? "Save Changes" : "switch to What IF"}
              </button>
            </>
          )}
          {!isAdvancedView && !brand && !hideAllConfig && (
            <button
              className={classNames(styles.setting__button, {
                [styles.cro]: isCRO,
              })}
              onClick={() => setIsOpenModal(true)}
            >
              <Setting />
            </button>
          )}
        </div>
      </div>
      {isAdvancedView ? (
        <AdvancedView isCRO={isCRO} showHistorical={showHistorical} />
      ) : (
        <DefaultView
          choosenWidgets={getChoosenWidgets()}
          customTitles={titles}
          showWhatIf={showWhatIf}
          isCRO={isCRO}
          alwaysDefault={alwaysDefault}
          showHistorical={showHistorical}
        />
      )}
      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onApply={onApply}
        choosedWidgets={choosedWidgets}
        setChoosedWidgets={setChoosedWidgets}
        maxWidgets={MAXIMUM_WIDGETS}
        allowedGroups={allowedGroups}
        isCRO={isCRO}
        showHistorical={showHistorical}
        setShowHistorical={setShowHistorical}
      />
    </div>
  );
};
