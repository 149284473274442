import { TypeTab } from "src/pages/AdsAnalitics/components/SanKey/Chart";
import { create } from "zustand";
import { ELEMENT_ID } from "./element-id";
import { CanvaElement } from "./canva.types";

export interface ICanvaState {
  elements: CanvaElement[];
  asin: string[];
  isWhatIf: boolean;
  isOpenElements: boolean;
  isZoomDisabled: boolean;
  campaignsTab: TypeTab;
  saturationKeys: string[];
}

interface CanvaStore extends ICanvaState {
  addElement: (element: CanvaElement) => void;
  removeElement: (id: string) => void;
  removeElementByName: (elementId: ELEMENT_ID) => void;
  updateElementPosition: (id: string, x: number, y: number) => void;
  clearCanvas: () => void;
  setAsin: (asin: string[]) => void;
  setIsWhatIf: (isWhatIf: boolean) => void;
  setIsOpenElements: (isOpenElements: boolean) => void;
  disableZoom: () => void;
  enableZoom: () => void;
  setCampaignsTab: (campaignsTab: TypeTab) => void;
  setSaturationKeys: (saturationKeys: string[]) => void;
}

export const useCanvaStore = create<CanvaStore>((set) => ({
  elements: [],
  asin: [],
  isWhatIf: false,
  isOpenElements: true,
  isZoomDisabled: false,
  campaignsTab: "Campaigns",
  saturationKeys: [],
  setSaturationKeys: (saturationKeys) => set({ saturationKeys }),
  setCampaignsTab: (campaignsTab) => set({ campaignsTab }),
  disableZoom: () => set({ isZoomDisabled: true }),
  enableZoom: () => set({ isZoomDisabled: false }),
  setIsOpenElements: (isOpenElements) => set({ isOpenElements }),
  setIsWhatIf: (isWhatIf) => set({ isWhatIf }),
  setAsin: (asin) => set({ asin }),
  addElement: (element) =>
    set((state) => ({
      elements: [...state.elements, element],
    })),

  removeElement: (id) =>
    set((state) => ({
      elements: state.elements.filter((el) => el.id !== id),
    })),

  removeElementByName: (elementId) =>
    set((state) => ({
      elements: state.elements.filter((el) => el.elementId !== elementId),
    })),

  updateElementPosition: (id, x, y) =>
    set((state) => ({
      elements: state.elements.map((el) =>
        el.id === id ? { ...el, x, y } : el,
      ),
    })),

  clearCanvas: () =>
    set(() => ({
      elements: [],
    })),
}));
