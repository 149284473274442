import "./index.css";
import { Rating } from "@pages/Dashboard/features/Products/Rating";
import ProductCode from "@components/ProductCode";
import DataBlock from "@components/DataBlock";
import { mockDataBlock } from "./mock";
import { useNavigate } from "react-router-dom";
import { useProductStore } from "src/store/overviewProduct.state";
import { useMaintenance } from "src/store/maintenance.state";
import { useEffect, useState } from "react";
import { CategoriesService } from "@services/amazon/categories/categories.service";

export const Description = ({ prod }) => {
  const { setSelectedProduct } = useProductStore();
  const navigate = useNavigate();
  const { maintenance } = useMaintenance();
  const [ratings, setRatings] = useState<{ [key: string]: number | null }>({});
  const [spiders, setSpiders] = useState<any>(null);
  const [scoreMain, setScoreMain] = useState<{ [key: string]: number | null }>(
    {},
  );

  const { selectedProduct, selectedProductToCompare } = useProductStore();

  const handleTitleClick = async () => {
    setSelectedProduct(prod);
    navigate("/my-product");
  };

  const handleGenerateImage = (asin: string) => {
    setSelectedProduct(prod);
    navigate(`/CRO-module/cro-photo-update/${asin}`);
  };

  const fetchCategories = async () => {
    const spider = new CategoriesService();
    try {
      const spidersData = await spider.getCategories(selectedProduct);
      setSpiders(spidersData);
      setScoreMain(spidersData);
    } catch (error) {
      console.error("Error fetching categories:", error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      fetchCategories();
    }
  }, [selectedProduct]);

  const getScoresForAsins = (asins: string[]) => {
    const scores: { [key: string]: number | null } = {};

    const items = Array.isArray(spiders)
      ? spiders.flatMap((spider) => spider.items || [])
      : spiders?.items || [];

    asins.forEach((asin) => {
      const found = items.find((item) => item.asin === asin);
      scores[asin] = found ? found.score : null;
    });
    return scores;
  };

  useEffect(() => {
    if (
      spiders &&
      Array.isArray(spiders) &&
      selectedProductToCompare &&
      selectedProductToCompare.length > 0
    ) {
      const asins = selectedProductToCompare.flat().map((p) => p.asin);
      const newRatings = getScoresForAsins(asins);
      setRatings((prevRatings) => ({
        ...prevRatings,
        ...newRatings,
      }));
    }

    if (selectedProduct && spiders) {
      const score = getScoresForAsins([selectedProduct]);
      setRatings((prevRatings) => ({
        ...prevRatings,
        ...score,
      }));
    }
  }, [selectedProductToCompare, selectedProduct, spiders]);

  return (
    <div className="product-description">
      <picture className="product-description__picture">
        <img src={prod.image_link} alt={prod.item_name} />
      </picture>
      <div className="product-description__description">
        <div className="prod">
          <h2
            className="product-description__title heading-2"
            onClick={handleTitleClick}
            style={{ cursor: "pointer" }}
          >
            {prod.item_name}
          </h2>
          <div className="product-additional">
            <div className="product-score">
              <Rating rating={ratings[selectedProduct] || 4.5} />
              <span className="ratingValue">
                {ratings[selectedProduct]
                  ? ratings[selectedProduct].toFixed(1)
                  : ""}
              </span>

              <ProductCode code={prod.asin} />
            </div>
            <button
              type="button"
              className="btn primary"
              onClick={() => handleGenerateImage(prod.asin)}
            >
              Generate an image with AI
            </button>
          </div>

          {maintenance && <DataBlock data={mockDataBlock} />}
        </div>
      </div>
    </div>
  );
};
