import { useEffect, useState } from "react";
import { Button, Card } from "antd";
import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
  Line,
} from "recharts";
import styles from "./styles.module.scss";
import renderCustomizedBar, {
  colorMapping,
  data as dataMock,
  dataMetrics,
  generateData,
  legendColorMapping,
  nameChoosedMapping,
  brandLegendColorMapping,
} from "./utils";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import PoSalesTable from "../PoSalesTable";
import { SettingsModal } from "./modal";
import Setting from "@assets/icons/setting";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import classNames from "classnames";
import { InventoryPerformanceService } from "@services/inventory-performance/inventory-performance.service";
import { DatePeriod } from "@services/inventory-performance/inventory-performance.api";
import DaysSelect from "@pages/AdsAnalitics/components/Header/ui/CustomDays";

const brandKeys = [
  "crestSales",
  "lumineuxSales",
  "inoProSales",
  "guruNandaSales",
  "keidocSales",
];

const InventoryPerformance = ({ title }: { title?: string }) => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [dataPoints, setDataPoints] = useState(nameChoosedMapping);
  const [showForecast, setShowForecast] = useState<boolean>(false);
  const [isPoModal, setIsPoModal] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const options = ["Days", "Week", "Month"];
  const [data, setData] = useState(title ? dataMetrics : dataMock);

  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const [period, setPeriod] = useState<DatePeriod>("DAY");
  const { asinSelected, brandSelected } = useDashboardStore();

  const [isLoading, setIsLoading] = useState(false);
  const inventoryService = new InventoryPerformanceService();

  useEffect(() => {
    if (title) {
      setData(dataMetrics);
      return;
    }
    if (!dateRange.startDate || !dateRange.endDate) return;

    const fetchData = async () => {
      setIsLoading(true);
      const activeChecks = Object.entries(dataPoints)
        .filter(([_, isChecked]) => isChecked)
        .map(([key]) => key);

      try {
        let newData;
        if (showForecast) {
          const startDate = new Date(dateRange.endDate);
          startDate.setDate(startDate.getDate() + 1);

          const endDate = new Date(dateRange.endDate);
          const datesCount = Math.abs(
            new Date(dateRange.endDate).getDate() -
              new Date(dateRange.startDate).getDate(),
          );
          endDate.setDate(endDate.getDate() + datesCount + 1);

          newData = await inventoryService.getChartData(
            activeChecks,
            startDate,
            endDate,
            period,
            asinSelected,
            brandSelected,
          );
        } else {
          newData = await inventoryService.getChartData(
            activeChecks,
            dateRange.startDate,
            dateRange.endDate,
            period,
            asinSelected,
            brandSelected,
          );
        }

        setData(newData);
      } catch (error) {
        console.error("Error fetching inventory data:", error);
        setData(
          generateData(
            showForecast ? new Date(dateRange.endDate) : dateRange.startDate,
            showForecast ? new Date(dateRange.endDate) : dateRange.endDate,
            activeChecks,
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [showForecast, dateRange, dataPoints, asinSelected, brandSelected]);

  const usedKeys = title
    ? [...brandKeys]
    : Object.keys(dataPoints).filter((key) => dataPoints[key]);

  const handleSelect = (value: string) => {};

  return (
    <Card className={styles.card}>
      <div className={styles.headerBetween}>
        <h1 className={styles.title}>
          {title ||
            (isPoModal
              ? "PO Sales, Margin & ROI Dynamics"
              : "Inventory Performance")}
        </h1>

        {title ? (
          <DaysSelect
            options={options}
            defaultLabel="Week View"
            onSelect={handleSelect}
          />
        ) : (
          <Button
            type="text"
            icon={<Setting />}
            onClick={() => setIsOpenModal(true)}
          />
        )}
      </div>

      <div className={styles.legendContainer}>
        <div className={styles.legendContainer}>
          {usedKeys.map((key) => (
            <div className={styles.legendItem} key={key}>
              <span
                className={styles.colorDot}
                style={{
                  backgroundColor:
                    brandLegendColorMapping[key] ||
                    legendColorMapping[key] ||
                    "#5C5C5A",
                }}
              ></span>
              <span className={styles.legendText}>
                {WIDGET_METRICS_CONFIG[key]?.label || key}
              </span>
            </div>
          ))}
        </div>
      </div>

      {!isPoModal ? (
        <div className={styles.chartContainer}>
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart
              data={data}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
              onMouseMove={(state) => {
                if (state.isTooltipActive) {
                  setActiveLabel(state.activeLabel as string);
                } else {
                  setActiveLabel(null);
                }
              }}
              onMouseLeave={() => setActiveLabel(null)}
            >
              <CartesianGrid stroke="#1F1F20" vertical={false} />

              <YAxis
                yAxisId="left"
                tick={{ fill: "white" }}
                axisLine={{ stroke: "#1F1F20" }}
                ticks={[2500, 2000, 1500, 1000, 500, 0]}
                domain={[0, 2500]}
              />

              <YAxis yAxisId="right" hide={true} />

              <XAxis
                dataKey="date"
                tick={{ fill: "white" }}
                axisLine={{ stroke: "#1F1F20" }}
              />
              <Tooltip
                content={({ active, payload, label }) => (
                  <CustomTooltip
                    active={active}
                    payload={payload}
                    colorMetric={legendColorMapping}
                    label={label}
                  />
                )}
                cursor={{ fill: "transparent" }}
              />

              {usedKeys.map((key) => (
                <Bar
                  key={key}
                  yAxisId="left"
                  dataKey={key}
                  stackId="a"
                  name={WIDGET_METRICS_CONFIG[key]?.label || key}
                  shape={(props) =>
                    renderCustomizedBar({
                      ...props,
                      activeLabel,
                      showForecast,
                      colorMapping,
                    })
                  }
                  barSize={55}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>

          {usedKeys.includes("safetyStock") && (
            <div
              className={classNames(
                styles.borderOverlay,
                showForecast && styles.borderOverlayForecast,
              )}
            />
          )}
        </div>
      ) : (
        <PoSalesTable />
      )}

      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        setDataPoints={setDataPoints}
        dataPoints={dataPoints}
        showForecast={showForecast}
        setShowForecast={setShowForecast}
        isPoModal={isPoModal}
        setIsPoModal={setIsPoModal}
      />
    </Card>
  );
};

export default InventoryPerformance;
